import { ComponentProps } from "react";

const StatusIcon = (props: ComponentProps<"svg">) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
      <g clipPath="url(#clip0_1233_2684)">
        <path
          d="M7.5 1.25C4.05 1.25 1.25 4.05 1.25 7.5C1.25 10.95 4.05 13.75 7.5 13.75C10.95 13.75 13.75 10.95 13.75 7.5C13.75 4.05 10.95 1.25 7.5 1.25ZM7.5 10.3125C5.94375 10.3125 4.6875 9.05625 4.6875 7.5C4.6875 5.94375 5.94375 4.6875 7.5 4.6875C9.05625 4.6875 10.3125 5.94375 10.3125 7.5C10.3125 9.05625 9.05625 10.3125 7.5 10.3125ZM7.5 6.875C7.15625 6.875 6.875 7.15625 6.875 7.5C6.875 7.84375 7.15625 8.125 7.5 8.125C7.84375 8.125 8.125 7.84375 8.125 7.5C8.125 7.15625 7.84375 6.875 7.5 6.875Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1233_2684">
          <rect width="15" height="15" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default StatusIcon;
