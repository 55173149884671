import { ArrowUpRightSquare, X } from "lucide-react";
import { GetAllJobsQuery } from "../../__generated__/graphql";
import { ethers } from "ethers";

const SearchResultCard = ({ job, onClick }: { job: GetAllJobsQuery["actions"][0]; onClick: (job: GetAllJobsQuery["actions"][0]) => void }) => {
  return (
    <div onClick={() => onClick(job)} className="rounded-lg relative py-3 shadow-sm px-4 bg-white">
      <p className="truncate">{job.title}</p>
      <div className="flex items-end justify-between">
        <div className="flex  flex-wrap gap-x-2">
          {job.skills.slice(0, 2).map((skill, id) => (
            <p key={id} className="rounded-sm text-[8px] lg:text-xs text-primary px-2 py-[2px] text-center truncate border border-primary/30 bg-primary/10">
              {skill}
            </p>
          ))}
        </div>
        <div className="flex items-end flex-col">
          <p className="text-primary text-xs font-bold">{ethers.utils.formatEther(job.amount.toString())} MATIC</p>
        </div>
      </div>
      <div className="absolute top-1 text-primary right-2">
        <ArrowUpRightSquare strokeWidth={1.4} size={14} />
      </div>
    </div>
  );
};

export default SearchResultCard;
