import Web3Service from '.';

class TrustiffyProfile extends Web3Service {
  /**
   * Create a user profile and verifies it.
   * @date 11/6/2023 - 10:45:44 PM
   *
   * @async
   * @param {string} name user name
   * @param {string} bio The user's biography.
   * @param {string} profileUrl The URL to the user's profile picture.
   * @param {string} coverUrl The URL to the user's cover image.
   * @param {string[]} skills An array of user's skills.
   * @param {string} cvUrl The cvUrl or the user portfolio
   * @returns {unknown}
   */
  async verifyProfile(name: string, bio: string, profileUrl: string, coverUrl: string, skills: string[], cvUrl: string) {
    const tx = await this.freelanceContract.verifyProfile(name, bio, profileUrl, coverUrl, skills, cvUrl);
    return await this.transactionResult(tx);
  }

  /**
   * Update user profile and verifies it.
   * @date 11/6/2023 - 10:45:44 PM
   *
   * @async
   *  @param {string} name user name
   * @param {string} bio The user's biography.
   * @param {string} profileUrl The URL to the user's profile picture.
   * @param {string} coverUrl The URL to the user's cover image.
   * @param {string[]} skills An array of user's skills.
   * @param {string} cvUrl The cvUrl or the user portfolio
   * @returns {unknown}
   */
  async updateProfile(name: string, bio: string, profileUrl: string, coverUrl: string, skills: string[], cvUrl: string) {
    const tx = await this.freelanceContract.updateProfile(name, bio, profileUrl, coverUrl, skills, cvUrl);
    return await this.transactionResult(tx);
  }

  /**
   * Get current profile from the smart contract
   * @date 11/11/2023 - 6:40:13 PM
   *
   * @async
   * @param {string} userWalletAddress
   * @returns {unknown}
   */
  async getUserProfile(userWalletAddress: string) {
    try {
      const user = await this.freelanceContract.userProfiles(userWalletAddress);
      const formattedUser = {
        name: user?.name,
        bio: user?.bio,
        profileURL: user?.profileURL,
        coverURL: user?.coverURL,
        verified: user.verified,
      };
      return formattedUser;
    } catch (err) {
      console.log({ err });
    }
  }
}

export const trustiffyProfile = new TrustiffyProfile();
