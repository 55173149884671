import { Dispatch, ReactElement, createContext, useContext, useState } from "react";
import { GetAllJobsQuery } from "../__generated__/graphql";

interface JobContextT {
  jobDetails?: GetAllJobsQuery["actions"][0];
  setJobDetails: Dispatch<React.SetStateAction<GetAllJobsQuery["actions"][0] | undefined>>;
}

const defaultContext: JobContextT = {
  jobDetails: undefined,
  setJobDetails: () => undefined,
};

const JobContext = createContext(defaultContext);

export const useJobs = () => useContext(JobContext);

const JobsProvider = ({ children }: { children: ReactElement }) => {
  const [jobDetails, setJobDetails] = useState<GetAllJobsQuery["actions"][0]>();

  const value = {
    jobDetails,
    setJobDetails,
  };
  return <JobContext.Provider value={value}>{children}</JobContext.Provider>;
};

export default JobsProvider;
