import { freelanceMarketplace } from "../../lib/web3/trustiffy/trustiffyFreelanceMarketplace";
import { ChangeEvent, FormEvent, useState } from "react";
import toast from "react-hot-toast";
import { useAccount } from "wagmi";
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "../../components/ui/form";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import TipTapEditor from "../../components/TipTapEditor";
import clsx from "clsx";
import jobTypes from "../../utils/jobTypes";
import jobCategories from "../../utils/jobCategories";
import { AiOutlinePlusCircle } from "react-icons/ai";
import Container from "../../components/ui/Container";
import truncateString from "../../utils/trancate";
import { formatPictureUrl } from "../../utils/formatPicturesUrl";
import HorizontalBorderComponent from "../../components/ui/HorizontalBorderComponent";
import { useQuery } from "@apollo/client";
import { GET_USER_PROFILE } from "../../lib/apollo/queries";
import { Link } from "react-router-dom";

export const formSchema = z.object({
  title: z.string().min(3, { message: "DisplayName Name is required" }).max(44, {
    message: "Only 20 Character long",
  }),
  price: z.string({
    required_error: "Please Select a one",
  }),
  jobType: z.string(),
  location: z.string(),
  deadline: z.string(),
  categories: z.array(z.string()),
});

export type FormSchemaType = z.infer<typeof formSchema>;

const CreateJob = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState<string[]>([]);
  const [skills, setSkills] = useState<string[]>([]);
  const [newSkill, setNewSkill] = useState<string>("");
  const [notes, setNote] = useState<null | string>(null);
  const { address: walletAddress, isConnected } = useAccount();

  const { data, loading } = useQuery(GET_USER_PROFILE, { variables: { userAddress: walletAddress } });

  const profile = data?.userProfileActions[0];

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(formSchema),
  });

  const onAddCategory = (e: any) => {
    const categoryHasBeenAdded = categories.indexOf(e.target.value) !== -1;
    if (categoryHasBeenAdded) return;
    setCategories((prev) => [...prev, e.target.value]);
  };

  const onRemoveCategory = (category: string) => {
    const newCategories = categories.filter((c) => c.toLowerCase() !== category.toLowerCase());
    setCategories(newCategories);
  };

  const onNewSkillChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNewSkill(e.target.value);
  };

  const onAddSkills = () => {
    if (!newSkill.trim()) return;
    const skillHasBeenAdded = skills.indexOf(newSkill.trim()) !== -1;
    if (skillHasBeenAdded) return;
    setSkills((prev) => [...prev, newSkill.trim()]);
    setNewSkill("");
  };

  const onRemoveSkill = (skill: string) => {
    const newSkills = skills.filter((c) => c.toLowerCase() !== skill.toLowerCase());
    setSkills(newSkills);
    setNewSkill("");
  };

  const note = {
    name: "Job Description",
  };

  const onSubmit: SubmitHandler<FormSchemaType> = async (data) => {
    const { categories, deadline: duration, jobType, location, price, title: jobTitle } = data;

    const jobDescription = notes;
    const durationTimestamp = new Date(duration).getTime() - new Date().getTime();

    const jobCategories = categories;
    const requiredSkills = skills;

    if (!jobTitle || !jobDescription || requiredSkills.length === 0 || Number(price) <= 0 || !jobType || jobCategories.length === 0) return;

    try {
      setIsLoading(true);
      await freelanceMarketplace.createJob(walletAddress as string, jobTitle, jobDescription, Number(price), jobType, durationTimestamp, categories, skills);
      toast.success("Job successfuly listed");
      setNote("");
      setSkills([]);
      setCategories([]);
    } catch (err: any) {
      toast.error(err.reason ?? err.data.message);
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container className="mt-6 pb-20">
      <div className="lg:w-8/12 xl:w-7/12">
        <Form {...form}>
          <form className="space-y-7" onSubmit={form.handleSubmit(onSubmit)}>
            <div className="flex gap-3 mb-12 flex-wrap items-center md:justify-between">
              {isConnected && (
                <div className="flex px-3 py-3 bg-primary/10 rounded-md items-center gap-x-2">
                  <div className="relative">
                    <div className="w-8 rounded-full h-8 overflow-hidden">
                      <img alt="img" className="object-cover h-full w-full" width={26} height={26} loading="lazy" src={profile?._profileURL ?? formatPictureUrl(false)} />
                    </div>
                    <div className="bg-green-500 rounded-full absolute -bottom-1 w-3 -right-1 h-3 border-[2px] border-white" />
                  </div>
                  <p className="text-primary">{truncateString(walletAddress as string, 6, 7)}</p>
                </div>
              )}
              <div className="flex w-full md:w-fit items-center gap-x-3">
                <Link to="/jobs" className="border rounded-md border-primary-shade px-10  py-2 text-neutral-700">
                  Cancel
                </Link>
                <button
                  type="submit"
                  disabled={isLoading}
                  className={clsx(!isLoading ? "bg-primary md:w-fit w-full text-white" : "bg-neutral-500/30", " px-10  py-2 rounded-md font-medium")}
                >
                  {isLoading ? "Listing your job..." : "Publish"}
                </button>
              </div>
            </div>
            <div className="mt-2 grow space-y-6">
              <FormField
                name="title"
                control={form.control}
                render={({ field }) => (
                  <FormItem>
                    <div className="border px-8 py-8 bg-white border-primary-shade rounded-xl">
                      <FormLabel>Title</FormLabel>
                      <HorizontalBorderComponent className="py-1" />
                      <FormControl>
                        <div className="w-full">
                          <input className="w-full focus:outline-none h-10" placeholder="Job title" {...field} />
                        </div>
                      </FormControl>
                    </div>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="border px-8 py-8 space-y-2 bg-white border-primary-shade rounded-xl">
              <FormLabel>Enter job's Description</FormLabel>
              <HorizontalBorderComponent className="py-1" />
              <div className="w-full flex gap-1 py-2 flex-wrap">
                <TipTapEditor setNote={setNote} note={note} />
              </div>
            </div>

            <div className="border px-8 py-8 space-y-2 bg-white border-primary-shade rounded-xl">
              <FormLabel>Payement & Details</FormLabel>
              <HorizontalBorderComponent className="py-1" />

              <FormField
                name="price"
                control={form.control}
                render={({ field }) => (
                  <FormItem>
                    <FormDescription>Payement & Details</FormDescription>
                    <FormControl>
                      <div className="w-full">
                        <input
                          className="w-full px-4  bg-primary-white-shade rounded-md text-primary placeholder:text-primary md:py-3 py-2 focus:outline-none"
                          placeholder="1.00 ETH"
                          {...field}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                name="deadline"
                control={form.control}
                render={({ field }) => (
                  <FormItem className="mt-3">
                    <FormDescription>Dead line</FormDescription>

                    <FormControl>
                      <div className="w-full">
                        <input className="w-full  rounded-md bg-primary-white-shade px-4 text-primary  md:py-3 py-2  focus:outline-none " type="date" placeholder="" {...field} />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="border px-8 py-8 space-y-2 bg-white border-primary-shade rounded-xl">
              <FormLabel>Job categories</FormLabel>
              <HorizontalBorderComponent className="py-1" />

              <div className="w-full flex gap-1 flex-wrap">
                {categories.map((category, index) => {
                  return (
                    <span
                      key={`category-${index}`}
                      onClick={() => onRemoveCategory(category)}
                      className="bg-primary-white-shade relative hover:opacity-50 transition-all cursor-pointer rounded-[2px] text-xs border border-primary-white-shade p-1"
                    >
                      {category}
                    </span>
                  );
                })}
              </div>
              <select
                onClick={onAddCategory}
                defaultValue={jobCategories[0]}
                className="flex h-10 appearance-none w-full rounded-md border bg-primary-white-shade outline-none border-gray-200 px-3 py-2 ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-500 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-neutral-500/30 focus-visible:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50 dark:border-gray-800 dark:bg-gray-950 dark:ring-offset-gray-950 dark:placeholder:text-gray-400 dark:focus-visible:ring-gray-300"
              >
                {jobCategories.map((jobCategorie, index) => (
                  <option key={`job-category-option-${index}`} value={jobCategorie} className="outline-none">
                    {jobCategorie}
                  </option>
                ))}
              </select>
            </div>

            <div className="border px-8 space-y-2 py-8 bg-white border-primary-shade rounded-xl">
              <FormLabel>Add Skill(s) for this job</FormLabel>
              <HorizontalBorderComponent className="py-1" />

              <div className="w-full flex gap-1  flex-wrap">
                {skills.map((skill, index) => {
                  return (
                    <span
                      key={`category-${index}`}
                      onClick={() => onRemoveSkill(skill)}
                      className="bg-primary-white-shade relative hover:opacity-50 transition-all cursor-pointer rounded-[2px] text-xs border border-white-shade p-1"
                    >
                      {skill}
                    </span>
                  );
                })}
              </div>
              <div className="flex items-center gap-2">
                <input
                  placeholder="JavaScript"
                  className="w-full px-4 focus:outline-none border border-neutral-200 md:py-3 py-2 rounded-md"
                  value={newSkill}
                  onChange={onNewSkillChange}
                />
                <button type="button" className="bg-blue-600 text-sm lg:text-base rounded-xl p-2.5 text-white" onClick={onAddSkills}>
                  <AiOutlinePlusCircle className="text-white stroke-white text-xl" />
                </button>
              </div>
            </div>

            <FormField
              name="jobType"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <div className="border px-8 space-y-2 py-8 bg-white border-primary-shade rounded-xl">
                    <FormLabel>Job Type</FormLabel>
                    <HorizontalBorderComponent className="py-1" />

                    <FormControl>
                      <div className="w-full">
                        <select
                          {...field}
                          defaultValue={jobTypes[0]}
                          className="flex h-10 appearance-none w-full outline-none rounded-md border border-gray-200 bg-primary-white-shade px-3 md:py-3 py-2 text-sm ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-950 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 dark:border-gray-800 dark:bg-gray-950 dark:ring-offset-gray-950 dark:placeholder:text-gray-400 dark:focus-visible:ring-gray-300"
                        >
                          {jobTypes.map((jobType, index) => (
                            <option key={`job-type-option-${index}`} value={jobType} className="outline-none">
                              {jobType}
                            </option>
                          ))}
                        </select>
                      </div>
                    </FormControl>
                    <FormMessage />
                  </div>
                </FormItem>
              )}
            />

            <div className="flex justify-end items-center gap-x-3">
              <Link to="/jobs" className="border rounded-md border-primary-shade  px-10 py-2 text-neutral-700">
                Cancel
              </Link>
              <button disabled={isLoading} className={clsx(!isLoading ? "bg-primary md:w-fit w-full text-white" : "bg-neutral-500/30", " px-10 py-2 rounded-md font-medium")}>
                {isLoading ? "Listing your job..." : "Publish"}
              </button>
            </div>
          </form>
        </Form>
      </div>
    </Container>
  );
};

export default CreateJob;
