import { useXmtp } from "../../contexts/XmtpProvider";
import ConversationCard from "./ConversationCard";

const ConversationPannel = ({ onConversationClicked }: { onConversationClicked: () => void }) => {
  const xmtp = useXmtp();

  return (
    <div className="flex flex-col">
      {xmtp?.conversations.map((conversation) => (
        <ConversationCard onClick={onConversationClicked} key={conversation.id} conversation={conversation} />
      ))}
    </div>
  );
};

export default ConversationPannel;
