import { useState } from "react";
import { Link } from "react-router-dom";
import truncateString from "../../../utils/trancate";
import { CgSpinnerTwoAlt } from "react-icons/cg";
import { useQuery } from "@apollo/client";
import { GET_JOB_APPLICATIONS, GET_USER_PROFILE } from "../../../lib/apollo/queries";
import { useAccount } from "wagmi";
import { ChevronDown, ChevronRight } from "lucide-react";
import HorizontalBorderComponent from "../../ui/HorizontalBorderComponent";
import clsx from "clsx";
import { GetAllJobsQuery } from "../../../__generated__/graphql";
import ApplicantProfile from "./ApplicantProfile";

const Applicants = ({
  jobDetails,
  chooseFreelanceLoading,
  onChooseFeelancer,
}: {
  jobDetails: GetAllJobsQuery["actions"][0];
  chooseFreelanceLoading: boolean;
  onChooseFeelancer: (freelancerAddress: string) => void;
}) => {
  const [currentBlockNumber, setCurrentBlockNumber] = useState<number | null>(null);
  const { data, loading } = useQuery(GET_JOB_APPLICATIONS, { variables: { jobId: jobDetails.Trustiffy_id } });
  const { address } = useAccount();

  const [openList, setOpenList] = useState(false);
  if (!data?.freelancerApplieds || data.freelancerApplieds.length === 0 || loading) return null;
  return (
    <div className="mt-2 px-6 py-4 rounded-lg bg-primary-white-shade ">
      <button
        onClick={() => {
          setOpenList((prev) => !prev);
        }}
        className="flex w-full text-primary items-center justify-between"
      >
        <span className="text-lg lg:text-2xl font-freigeist font-black">List of Applicants</span>
        <span className={clsx(openList ? "rotate-180" : null, "transition duration-200 ease-in-out")}>
          <ChevronDown />
        </span>
      </button>
      {openList && (
        <div className="w-full mt-5">
          {data?.freelancerApplieds.map((applicant, index: number) => (
            <>
              <div key={`applications-${index}`} className="flex justify-between gap-2 sm:flex-row w-full flex-col items-start sm:items-center">
                <ApplicantProfile applicantAddress={applicant.freelancer} />
                {/* Only the jobOwner can see the button to choose the freelancer */}
                {jobDetails.employer.toLocaleLowerCase() === address?.toLocaleLowerCase() && (
                  <div className="flex flex-col sm:flex-row w-full justify-end gap-2">
                    <Link to={`/${applicant.freelancer}/profile`} className="border w-full sm:w-fit text-center text-xs border-primary py-3 text-primary px-4 rounded-md">
                      View Profile
                    </Link>
                    <button
                      disabled={chooseFreelanceLoading}
                      onClick={() => {
                        setCurrentBlockNumber(index);
                        onChooseFeelancer(applicant.freelancer);
                      }}
                      className="bg-primary w-full sm:w-fit px-4 text-xs py-3 text-white rounded-md"
                    >
                      {chooseFreelanceLoading && currentBlockNumber === index ? <CgSpinnerTwoAlt className="text-xl text-white animate-spin" /> : "Choose Talent"}
                    </button>
                  </div>
                )}
              </div>
              <div className="mt-3 mb-3">
                <HorizontalBorderComponent />
              </div>
            </>
          ))}
        </div>
      )}
    </div>
  );
};

export default Applicants;
