const TrustiffyReputations = ({ className }: { className: string }) => {
  return (
    <svg className={className} viewBox="0 0 175 174" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M110.34 108.423L174.979 109.216L174.005 70.4286L133.273 69.5471L167.452 36.4897L140.711 7.04664L105.647 43.8064L102.725 0.523315L64.7382 1.14039L65.6237 64.875L109.366 63.9053L110.34 108.423Z"
        fill="white"
      />
      <path
        d="M66.4206 110.274L65.6237 64.875L0.807495 64.9632L1.78151 103.75L42.513 104.72L8.33396 137.778L35.0751 167.221L70.1395 130.373L73.0616 173.744L111.048 173.127L110.34 108.423L66.4206 110.274Z"
        fill="white"
      />
    </svg>
  );
};

export default TrustiffyReputations;
