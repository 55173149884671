import { useProfileProvider } from '../../../contexts/ProfileProvider';
import { Profile } from '../../../types/profileData';

const ProfileBioDetails = ({ profile }: { profile: Profile }) => {
  if (!profile?.cvMetadataURI) return <></>;
  return (
    <div className="w-full flex flex-col gap-1 my-10">
      <p className="flex justify-between items-center">
        <span className="text-sm">Portfolio</span>
        <a href={profile?.cvMetadataURI} rel="noreferrer" target="_blank" className="underline">
          {profile?.cvMetadataURI}
        </a>
      </p>
    </div>
  );
};

export default ProfileBioDetails;
