/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  query getAllJobs($orderDirection:OrderDirection,$filterBy:[Action_filter]=[{ skills_contains: [] }]) {\n    actions(first: 20, orderBy: blockTimestamp,orderDirection: $orderDirection, where:{\n      or: $filterBy\n    }) {\n    Trustiffy_id\n    actionType\n    amount\n    blockNumber\n    blockTimestamp\n    categories\n    description\n    duration\n    employer\n    id\n    jobType\n    listedOn\n    skills\n    title\n    status\n    transactionHash\n  }}\n": types.GetAllJobsDocument,
    "\n  query FreelanceApplieds ($walletAddress: Bytes!, $jobId: BigInt!) {\n    freelancerApplieds (where: {jobId: $jobId, freelancer: $walletAddress}) {\n    blockNumber\n    blockTimestamp\n    freelancer\n    id\n    jobId\n    transactionHash\n  }\n  }\n": types.FreelanceAppliedsDocument,
    " \n  query ChoosenFreelancer($jobId: BigInt!) {\n    freelancerChosens(where: {jobId: $jobId}) {\n    blockNumber\n    blockTimestamp\n    freelancer\n    id\n    jobId\n    transactionHash\n  }\n  }\n": types.ChoosenFreelancerDocument,
    "  \n  query AllMyJobs($walletAddress: Bytes!) {\n    actions(where:{employer: $walletAddress}) {\n    Trustiffy_id\n    actionType\n    amount\n    blockNumber\n    blockTimestamp\n    categories\n    description\n    duration\n    employer\n    id\n    jobType\n    listedOn\n    skills\n    status\n    title\n    transactionHash\n  }\n  }\n": types.AllMyJobsDocument,
    "\n  query jobApplications($jobId: BigInt!) {\n    freelancerApplieds(where: {jobId: $jobId}) {\n    transactionHash\n    jobId\n    id\n    freelancer\n    blockTimestamp\n    blockNumber\n  }\n  }\n": types.JobApplicationsDocument,
    "\n  query JobSubmission($jobId: BigInt!) {\n  workSubmitteds(where: {jobId: $jobId}) {\n    transactionHash\n    submission\n    jobId\n    id\n    freelancer\n    blockTimestamp\n    blockNumber\n  }\n}\n": types.JobSubmissionDocument,
    "\n  query submissionPayment($jobId: BigInt!){\n  paymentMades(where: {jobId: $jobId}) {\n    transactionHash\n    jobId\n    id\n    freelancer\n    employer\n    blockTimestamp\n    blockNumber\n    amount\n  }\n}\n": types.SubmissionPaymentDocument,
    "\n  query UserProfile($userAddress: Bytes!) {\n  userProfileActions (\n    orderBy: blockTimestamp,\n    orderDirection: desc,\n    first: 1,\n    where: {userAddress: $userAddress}) {\n    verified\n    userAddress\n    name\n    id\n    cvMetadataURI\n    actionType\n    _skills\n    _profileURL\n    _coverURL\n    _bio\n  }\n}\n": types.UserProfileDocument,
    "\nquery GetTalents ($orderDirection:OrderDirection,$filterBy:[UserProfileAction_filter]=[{ _skills_contains: []}]){\n  userProfileActions(orderDirection: $orderDirection,where: {or: $filterBy}){\n    verified\n    userAddress\n    transactionHash\n    name\n    id\n    cvMetadataURI\n    actionType\n    _skills\n    _profileURL\n    _coverURL\n    _bio\n  }\n}\n": types.GetTalentsDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getAllJobs($orderDirection:OrderDirection,$filterBy:[Action_filter]=[{ skills_contains: [] }]) {\n    actions(first: 20, orderBy: blockTimestamp,orderDirection: $orderDirection, where:{\n      or: $filterBy\n    }) {\n    Trustiffy_id\n    actionType\n    amount\n    blockNumber\n    blockTimestamp\n    categories\n    description\n    duration\n    employer\n    id\n    jobType\n    listedOn\n    skills\n    title\n    status\n    transactionHash\n  }}\n"): (typeof documents)["\n  query getAllJobs($orderDirection:OrderDirection,$filterBy:[Action_filter]=[{ skills_contains: [] }]) {\n    actions(first: 20, orderBy: blockTimestamp,orderDirection: $orderDirection, where:{\n      or: $filterBy\n    }) {\n    Trustiffy_id\n    actionType\n    amount\n    blockNumber\n    blockTimestamp\n    categories\n    description\n    duration\n    employer\n    id\n    jobType\n    listedOn\n    skills\n    title\n    status\n    transactionHash\n  }}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query FreelanceApplieds ($walletAddress: Bytes!, $jobId: BigInt!) {\n    freelancerApplieds (where: {jobId: $jobId, freelancer: $walletAddress}) {\n    blockNumber\n    blockTimestamp\n    freelancer\n    id\n    jobId\n    transactionHash\n  }\n  }\n"): (typeof documents)["\n  query FreelanceApplieds ($walletAddress: Bytes!, $jobId: BigInt!) {\n    freelancerApplieds (where: {jobId: $jobId, freelancer: $walletAddress}) {\n    blockNumber\n    blockTimestamp\n    freelancer\n    id\n    jobId\n    transactionHash\n  }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: " \n  query ChoosenFreelancer($jobId: BigInt!) {\n    freelancerChosens(where: {jobId: $jobId}) {\n    blockNumber\n    blockTimestamp\n    freelancer\n    id\n    jobId\n    transactionHash\n  }\n  }\n"): (typeof documents)[" \n  query ChoosenFreelancer($jobId: BigInt!) {\n    freelancerChosens(where: {jobId: $jobId}) {\n    blockNumber\n    blockTimestamp\n    freelancer\n    id\n    jobId\n    transactionHash\n  }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "  \n  query AllMyJobs($walletAddress: Bytes!) {\n    actions(where:{employer: $walletAddress}) {\n    Trustiffy_id\n    actionType\n    amount\n    blockNumber\n    blockTimestamp\n    categories\n    description\n    duration\n    employer\n    id\n    jobType\n    listedOn\n    skills\n    status\n    title\n    transactionHash\n  }\n  }\n"): (typeof documents)["  \n  query AllMyJobs($walletAddress: Bytes!) {\n    actions(where:{employer: $walletAddress}) {\n    Trustiffy_id\n    actionType\n    amount\n    blockNumber\n    blockTimestamp\n    categories\n    description\n    duration\n    employer\n    id\n    jobType\n    listedOn\n    skills\n    status\n    title\n    transactionHash\n  }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query jobApplications($jobId: BigInt!) {\n    freelancerApplieds(where: {jobId: $jobId}) {\n    transactionHash\n    jobId\n    id\n    freelancer\n    blockTimestamp\n    blockNumber\n  }\n  }\n"): (typeof documents)["\n  query jobApplications($jobId: BigInt!) {\n    freelancerApplieds(where: {jobId: $jobId}) {\n    transactionHash\n    jobId\n    id\n    freelancer\n    blockTimestamp\n    blockNumber\n  }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query JobSubmission($jobId: BigInt!) {\n  workSubmitteds(where: {jobId: $jobId}) {\n    transactionHash\n    submission\n    jobId\n    id\n    freelancer\n    blockTimestamp\n    blockNumber\n  }\n}\n"): (typeof documents)["\n  query JobSubmission($jobId: BigInt!) {\n  workSubmitteds(where: {jobId: $jobId}) {\n    transactionHash\n    submission\n    jobId\n    id\n    freelancer\n    blockTimestamp\n    blockNumber\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query submissionPayment($jobId: BigInt!){\n  paymentMades(where: {jobId: $jobId}) {\n    transactionHash\n    jobId\n    id\n    freelancer\n    employer\n    blockTimestamp\n    blockNumber\n    amount\n  }\n}\n"): (typeof documents)["\n  query submissionPayment($jobId: BigInt!){\n  paymentMades(where: {jobId: $jobId}) {\n    transactionHash\n    jobId\n    id\n    freelancer\n    employer\n    blockTimestamp\n    blockNumber\n    amount\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query UserProfile($userAddress: Bytes!) {\n  userProfileActions (\n    orderBy: blockTimestamp,\n    orderDirection: desc,\n    first: 1,\n    where: {userAddress: $userAddress}) {\n    verified\n    userAddress\n    name\n    id\n    cvMetadataURI\n    actionType\n    _skills\n    _profileURL\n    _coverURL\n    _bio\n  }\n}\n"): (typeof documents)["\n  query UserProfile($userAddress: Bytes!) {\n  userProfileActions (\n    orderBy: blockTimestamp,\n    orderDirection: desc,\n    first: 1,\n    where: {userAddress: $userAddress}) {\n    verified\n    userAddress\n    name\n    id\n    cvMetadataURI\n    actionType\n    _skills\n    _profileURL\n    _coverURL\n    _bio\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery GetTalents ($orderDirection:OrderDirection,$filterBy:[UserProfileAction_filter]=[{ _skills_contains: []}]){\n  userProfileActions(orderDirection: $orderDirection,where: {or: $filterBy}){\n    verified\n    userAddress\n    transactionHash\n    name\n    id\n    cvMetadataURI\n    actionType\n    _skills\n    _profileURL\n    _coverURL\n    _bio\n  }\n}\n"): (typeof documents)["\nquery GetTalents ($orderDirection:OrderDirection,$filterBy:[UserProfileAction_filter]=[{ _skills_contains: []}]){\n  userProfileActions(orderDirection: $orderDirection,where: {or: $filterBy}){\n    verified\n    userAddress\n    transactionHash\n    name\n    id\n    cvMetadataURI\n    actionType\n    _skills\n    _profileURL\n    _coverURL\n    _bio\n  }\n}\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;