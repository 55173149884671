const jobCategories = [
  'Design',
  'Software development',
  'Tech Writing',
  'UI/UX',
  'Graphic design',
  'Motion Design',
  'Sales',
  'Marketing',
  'Engineering',
  'Customer',
  'Service',
  'Healthcare',
];
export default jobCategories;
