import { gql } from "../../__generated__";

export const GET_ALL_JOBS = gql(`
  query getAllJobs($orderDirection:OrderDirection,$filterBy:[Action_filter]=[{ skills_contains: [] }]) {
    actions(first: 20, orderBy: blockTimestamp,orderDirection: $orderDirection, where:{
      or: $filterBy
    }) {
    Trustiffy_id
    actionType
    amount
    blockNumber
    blockTimestamp
    categories
    description
    duration
    employer
    id
    jobType
    listedOn
    skills
    title
    status
    transactionHash
  }}
`);

export const GET_MY_JOB_APPLICATION = gql(`
  query FreelanceApplieds ($walletAddress: Bytes!, $jobId: BigInt!) {
    freelancerApplieds (where: {jobId: $jobId, freelancer: $walletAddress}) {
    blockNumber
    blockTimestamp
    freelancer
    id
    jobId
    transactionHash
  }
  }
`);

export const GET_JOB_CHOOSEN_FREELANCER = gql(` 
  query ChoosenFreelancer($jobId: BigInt!) {
    freelancerChosens(where: {jobId: $jobId}) {
    blockNumber
    blockTimestamp
    freelancer
    id
    jobId
    transactionHash
  }
  }
`);

export const GET_ALL_MY_JOBS = gql(`  
  query AllMyJobs($walletAddress: Bytes!) {
    actions(where:{employer: $walletAddress}) {
    Trustiffy_id
    actionType
    amount
    blockNumber
    blockTimestamp
    categories
    description
    duration
    employer
    id
    jobType
    listedOn
    skills
    status
    title
    transactionHash
  }
  }
`);

export const GET_JOB_APPLICATIONS = gql(`
  query jobApplications($jobId: BigInt!) {
    freelancerApplieds(where: {jobId: $jobId}) {
    transactionHash
    jobId
    id
    freelancer
    blockTimestamp
    blockNumber
  }
  }
`);

export const GET_JOB_SUBMISSION = gql(`
  query JobSubmission($jobId: BigInt!) {
  workSubmitteds(where: {jobId: $jobId}) {
    transactionHash
    submission
    jobId
    id
    freelancer
    blockTimestamp
    blockNumber
  }
}
`);

export const GET_SUBMISSION_PAYMENT = gql(`
  query submissionPayment($jobId: BigInt!){
  paymentMades(where: {jobId: $jobId}) {
    transactionHash
    jobId
    id
    freelancer
    employer
    blockTimestamp
    blockNumber
    amount
  }
}
`);

export const GET_USER_PROFILE = gql(`
  query UserProfile($userAddress: Bytes!) {
  userProfileActions (
    orderBy: blockTimestamp,
    orderDirection: desc,
    first: 1,
    where: {userAddress: $userAddress}) {
    verified
    userAddress
    name
    id
    cvMetadataURI
    actionType
    _skills
    _profileURL
    _coverURL
    _bio
  }
}
`);

// TODO: This query might be improved, we should only query verified profiles, actionType = "PV", this somehow is not working
export const GET_ALL_TALENTS = gql(`
query GetTalents ($orderDirection:OrderDirection,$filterBy:[UserProfileAction_filter]=[{ _skills_contains: []}]){
  userProfileActions(orderDirection: $orderDirection,where: {or: $filterBy}){
    verified
    userAddress
    transactionHash
    name
    id
    cvMetadataURI
    actionType
    _skills
    _profileURL
    _coverURL
    _bio
  }
}
`);
