/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useParams } from "react-router-dom";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { IoIosMail } from "react-icons/io";
import { useAccount } from "wagmi";
import EditProfileButton from "./partial/EditProfileButton";
import ProfileImageAndName from "./partial/ProfileImageAndName";
import ProfileBio from "./partial/ProfileBio";
import ProfileBioDetails from "./partial/ProfileBioDetails";
import { useQuery } from "@apollo/client";
import { GET_USER_PROFILE } from "../../lib/apollo/queries";
import { useXmtp } from "../../contexts/XmtpProvider";
import { useState } from "react";
import UtuSocialConnector from "../modals/UtuSocialConnector";
import ProfileSkills from "./partial/ProfileSkills";

const DeskProfileView = () => {
  const { address: walletAddress } = useAccount();
  const { address } = useParams();
  const xmtp = useXmtp();
  const { data, loading } = useQuery(GET_USER_PROFILE, { variables: { userAddress: address } });
  const profile = data?.userProfileActions[0];
  const [isUTUConnectorModalOpened, setIsUTUConnectorModalOpened] = useState(false);

  if (!address) return <></>;
  return (
    <>
      {isUTUConnectorModalOpened && <UtuSocialConnector isModalOpened={isUTUConnectorModalOpened} onCloseUTUConnectorModal={() => setIsUTUConnectorModalOpened(false)} />}
      <div className="max-w-xl w-96 mb-6 mt-32">
        <div className="bg-white relative w-full px-6 py-10 rounded-xl border shadow-sm">
          {address === walletAddress && (
            <div className="absolute top-3 right-2">
              <EditProfileButton as="updateButton" />
            </div>
          )}
          <div className="flex flex-col items-center space-y-5 justify-center w-full">
            <ProfileImageAndName profile={profile} isLoading={loading} />
            <div className="w-full flex flex-col mt-6 gap-2">
              {address !== walletAddress && (
                <>
                  <button className="flex gap-2 justify-center items-center w-full bg-primary py-2 rounded-full text-white mt-10 font-medium">
                    <span>
                      <BsFillPlusCircleFill className="text-xl" />
                    </span>
                    Follow
                  </button>
                  <button
                    onClick={() => xmtp?.sendMessage(profile?.userAddress ?? "", "Hello")}
                    className="flex gap-2 justify-center items-center w-full border border-primary py-2 rounded-full text-primary font-medium"
                  >
                    <span>
                      <IoIosMail className="text-2xl" />
                    </span>
                    Message
                  </button>
                </>
              )}
              {address === walletAddress && (
                <>
                  <button
                    className="flex gap-2 justify-center items-center w-full bg-primary py-2 rounded-md text-white font-medium"
                    onClick={() => {
                      setIsUTUConnectorModalOpened(true);
                    }}
                  >
                    Connect UTU socials
                  </button>
                </>
              )}
            </div>
            <ProfileBioDetails profile={profile} />
            <div className="w-full">
              <ProfileBio profile={profile} />
            </div>
            <div className="w-full space-y-3">
              <ProfileSkills profile={profile} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeskProfileView;

export const MobileProfileView = () => {
  const { address: walletAddress } = useAccount();
  const { address } = useParams();
  const xmtp = useXmtp();
  const { data, loading } = useQuery(GET_USER_PROFILE, { variables: { userAddress: address } });
  const profile = data?.userProfileActions[0];
  const [isUTUConnectorModalOpened, setIsUTUConnectorModalOpened] = useState(false);

  return (
    <>
      {isUTUConnectorModalOpened && <UtuSocialConnector isModalOpened={isUTUConnectorModalOpened} onCloseUTUConnectorModal={() => setIsUTUConnectorModalOpened(false)} />}
      <div className="flex flex-col items-center space-y-5 justify-center w-full">
        <div className="w-full -mt-10">
          <ProfileImageAndName profile={profile} isLoading={loading} />
        </div>
        <div className="w-full flex flex-col mt-6 gap-2">
          {address !== walletAddress && (
            <>
              <button className="flex gap-2 justify-center items-center w-full bg-primary py-2 rounded-full text-white mt-10 font-medium">
                <span>
                  <BsFillPlusCircleFill className="text-xl" />
                </span>
                Follow
              </button>
              <button
                onClick={() => xmtp?.sendMessage(profile?.userAddress ?? "", "Hello")}
                className="flex gap-2 justify-center items-center w-full border border-primary py-2 rounded-full text-primary font-medium"
              >
                <span>
                  <IoIosMail className="text-2xl" />
                </span>
                Message
              </button>
            </>
          )}
          {address === walletAddress && (
            <>
              <button
                className="flex gap-2 justify-center items-center w-full bg-primary py-2 rounded-md text-white font-medium"
                onClick={() => {
                  setIsUTUConnectorModalOpened(true);
                }}
              >
                Connect UTU socials
              </button>
            </>
          )}
        </div>
        <ProfileBioDetails profile={profile} />
        <div className="w-full">
          <ProfileBio profile={profile} />
        </div>
        <div className="w-full space-y-3">
          <ProfileSkills profile={profile} />
        </div>
      </div>
    </>
  );
};
