import Container from "../../ui/Container";

const advantages = [
  {
    title: "Get quality work done faster",
    subtitle: "Connect your Metamask wallet to access Trustiffy services.",
  },
  {
    title: "Pay when you’re happy",
    subtitle: "Connect your Metamask wallet to access Trustiffy services.",
  },
  {
    title: "Pay when you’re happy",
    subtitle: "Connect your Metamask wallet to access Trustiffy services.",
  },
];
const JoinTrustify = () => {
  return (
    <Container>
      <div className="flex md:pt-10 px-4 md:px-0 md:flex-row flex-col gap-y-10 md:gap-y-0 md:gap-x-36 items-stretch">
        <div className="lg:rounded-l-2xl relative h-[400px] rounded-2xl flex-1 flex flex-col justify-center items-center bg-[#EFF7FF]">
          <div className="w-full md:absolute bottom-0 max-w-[500px] lg:h-[500px] h-[400px]">
            <img alt="avatar" src="/mocks/trustiffy_2.png" width={600} height={600} className="w-full h-full object-contain" />
          </div>
        </div>
        <div className="flex-1 justify-around items-stretch  gap-y-5 flex flex-col">
          <div>
            <p className="text-xl md:text-2xl lg:text-4xl font-freigeist font-bold">Trustiffy Advantages.</p>
          </div>

          {advantages.map((advantage, index) => (
            <div className="flex gap-x-6 items-center" key={index}>
              <div>
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_1761_482)">
                    <path
                      d="M14 2.33325C7.55999 2.33325 2.33333 7.55992 2.33333 13.9999C2.33333 20.4399 7.55999 25.6666 14 25.6666C20.44 25.6666 25.6667 20.4399 25.6667 13.9999C25.6667 7.55992 20.44 2.33325 14 2.33325ZM11.6667 19.8333L5.83333 13.9999L7.47833 12.3549L11.6667 16.5316L20.5217 7.67659L22.1667 9.33325L11.6667 19.8333Z"
                      fill="#11B549"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1761_482">
                      <rect width="28" height="28" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className="flex flex-col gap-y-2 md:gap-y-4">
                <p className="text-sm md:text-base">{advantage.title}</p>
                <p className="text-xs font-extralight md:text-base">{advantage.subtitle}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
};

export default JoinTrustify;
