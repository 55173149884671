import { useEffect, useState } from "react";
import { customFilter } from "../../utils/filterCategories";
import { ChevronDown, XCircle } from "lucide-react";
import { FilteredData } from "../../pages/Jobs";

const FilterModalDropDown = ({
  onClose,
  filterModal,
  onSelectFilters,
}: {
  filterModal: boolean;
  onClose: () => void;
  onSelectFilters: (filters: FilteredData[] | undefined) => void;
}) => {
  useEffect(() => {
    if (filterModal) {
      document.body.className = "overflow-hidden";
    }
    return () => {
      document.body.className = "overflow-auto";
    };
  }, [filterModal]);

  return (
    <div className="bg-black/10 backdrop-blur-lg fixed bottom-0 left-0 right-0 top-0 w-full h-full max-md:px-5 z-20 overflow-y-auto pb-10">
      <div className="bg-primary-white-shade overflow-hidden max-h-[80vh] w-full max-w-3xl rounded-xl mt-24 mx-auto">
        <div className="flex justify-between bg-primary items-center  text-2xl  py-4 px-4 lg:px-10 font-bold">
          <h2 className="w-full font-freigeist text-white">Filters</h2>
          <XCircle
            onClick={() => {
              onSelectFilters(undefined);
              onClose();
            }}
            className="stroke-white cursor-pointer"
            size={24}
          />
        </div>
        <div className="space-y-2">
          <CustomFilter onSelectFilters={onSelectFilters} title="Skills" filterItems={customFilter} onClose={onClose} />
        </div>
      </div>
    </div>
  );
};

export default FilterModalDropDown;

const CustomFilter = ({
  title,
  filterItems,
  onClose,
  onSelectFilters,
}: {
  title: string;
  filterItems: string[];
  onClose: () => void;
  onSelectFilters: (filters: FilteredData[] | undefined) => void;
}) => {
  const isTalentFilter = window.location.href.includes("discover");
  const filterLocalStoragekey = isTalentFilter ? "talentsFiltered" : "jobFiltered";
  const getFilter = localStorage.getItem(filterLocalStoragekey);
  const [selectedItem, setSelectedItem] = useState<string[]>([]);
  const [openSelection, setOpenSelection] = useState(false);

  useEffect(() => {
    const stringifyFilters: FilteredData[] = JSON.parse(getFilter ?? "");
    const lastResult = stringifyFilters.flatMap((item) => item.skills_contains!);
    setSelectedItem(lastResult);
  }, []);

  const handleApplyFilters = () => {
    const saveFilters = selectedItem.map((item) => {
      const newItems = {
        skills_contains: [item],
      };
      return newItems;
    });
    onSelectFilters(saveFilters.length > 0 ? saveFilters : undefined);
    onClose();
    localStorage.setItem(filterLocalStoragekey, JSON.stringify(saveFilters));
  };

  const onRemoveFilter = (filterOption: string) => {
    setSelectedItem((prev) => {
      const updaetedFilters = prev.filter((item) => item !== filterOption);
      localStorage.setItem(filterLocalStoragekey, JSON.stringify(updaetedFilters));
      return updaetedFilters;
    });
  };

  return (
    <div className="py-6 space-y-3  px-4 md:px-10">
      <div className="space-y-2">
        <h2>
          {title} ({selectedItem.length})
        </h2>
        <div className="flex flex-wrap items-center gap-1">
          {selectedItem.length > 0 &&
            selectedItem.map((item, index) => (
              <div key={`filter-option-${index}`} className="relative">
                <button onClick={() => onRemoveFilter(item)} className="p-2 relative bg-primary-xs w-fit text-xs text-primary rounded-md">
                  {item}
                </button>
                <XCircle onClick={() => onRemoveFilter(item)} className="absolute cursor-pointer  text-black w-3 h-3 -top-1 -right-1" size={24} />
              </div>
            ))}
        </div>
      </div>

      <div
        role="button"
        onClick={() => setOpenSelection((prev) => !prev)}
        className="w-full cursor-pointer flex mt-2 justify-between items-center bg-white p-5 rounded-md border border-primary-shade"
      >
        <span>Select {title}</span>
        <ChevronDown className="text-primary" />
      </div>
      {openSelection && (
        <div className="bg-white flex flex-col border border-primary-shade h-36 overflow-y-scroll my-2 py-5 rounded-md">
          {filterItems.map((item, index) => (
            <p
              role="button"
              key={`${item}-${index}`}
              onClick={() => {
                setSelectedItem((prev) => {
                  return prev.includes(item) ? [...prev] : [...prev, item];
                });
              }}
              className="px-5 py-2 hover:bg-primary-sm"
            >
              {item}
            </p>
          ))}
        </div>
      )}

      <div className="flex items-center gap-2 font-medium mt-4">
        <button className="w-full p-3 bg-primary-xs text-primary rounded-md" onClick={onClose}>
          Cancel
        </button>
        <button onClick={handleApplyFilters} className="w-full p-3 bg-primary text-white rounded-md">
          Apply
        </button>
      </div>
    </div>
  );
};
