import { useState } from "react";
import EditProfileModal from "./EditProfileModal";

const EditProfileButton = ({ as }: { as: "verificationButton" | "updateButton" }) => {
  const [isEditProfileModal, setIsEditProfileModal] = useState(false);
  const toggleEditProfileModal = () => setIsEditProfileModal((prev) => !prev);

  return (
    <>
      {isEditProfileModal && <EditProfileModal isOpen={isEditProfileModal} onClose={toggleEditProfileModal} />}
      {as === "verificationButton" ? (
        <img
          alt="img"
          onClick={() => setIsEditProfileModal(true)}
          className="object-contain cursor-pointer rounded-full"
          width={30}
          height={30}
          loading="lazy"
          src="/mocks/placeholder.png"
        />
      ) : (
        <button
          onClick={() => setIsEditProfileModal(true)}
          className="flex bg-primary-white-shade gap-2 justify-center items-center w-full text-sm transition-all py-2 px-3 rounded-full text-primary font-medium"
        >
          Edit Profile
        </button>
      )}
    </>
  );
};

export default EditProfileButton;
