import { Profile } from '../../../types/profileData';

const ProfileBio = ({ profile }: { profile: Profile }) => {
  if (!profile?._bio) return <></>;
  return (
    <div className="w-full bg-primary/5 px-4 py-4 rounded-lg">
      <h2 className="uppercase text-sm text-gray-500 font-medium">About</h2>
      <div className="flex justify-start items-center mt-3 gap-2">
        <article className="text-sm line-clamp-6">{profile?._bio}</article>
      </div>
    </div>
  );
};

export default ProfileBio;
