import { sorts } from '../../../utils/filterCategories';
import HorizontalBorderComponent from '../../ui/HorizontalBorderComponent';

const SortingFilter = ({ onSelectSort }: { onSelectSort: (sort: string) => void }) => {
  return (
    <div className="absolute w-full grid grid-rows-1 top-[3rem] lg:top-[4.5rem] border border-primary-shade rounded-md bg-white">
      {sorts.map((sort, index) => (
        <div key={index} className="w-full">
          <p className="py-3" key={`sort-${index}`} onClick={() => onSelectSort(sort)}>
            {sort}
          </p>
          {index !== sorts.length - 1 && <HorizontalBorderComponent />}
        </div>
      ))}
    </div>
  );
};

export default SortingFilter;
