import { useEffect, useState } from "react";
import { FilteredData } from "../pages/Jobs";
import { OrderDirection } from "../__generated__/graphql";
import { DocumentNode, OperationVariables, TypedDocumentNode, useQuery } from "@apollo/client";

const storeDefaultFilters = [
  {
    skills_contains: [],
  },
];

type TUseFilterOption = {
  isTalent: boolean;
};

const useFilter = <T>(queryKey: DocumentNode | TypedDocumentNode<T, OperationVariables>, option?: TUseFilterOption) => {
  const getSortedJob = localStorage.getItem("jobSorted");
  const getFilteredJob = localStorage.getItem("jobFiltered");
  const filteredTalents = localStorage.getItem("talentsFiltered");
  const [sort, setSort] = useState(getSortedJob ?? "Recent");
  const [filteredData, setFilteredData] = useState<FilteredData[] | undefined>([
    {
      skills_contains: [],
    },
  ]);

  useEffect(() => {
    if (!getFilteredJob) localStorage.setItem("jobFiltered", JSON.stringify(storeDefaultFilters));
    if (!filteredTalents) localStorage.setItem("talentsFiltered", JSON.stringify(storeDefaultFilters));
  }, []);

  const talentFilterSchema = () => {
    return filteredData?.map((talentFilter) => ({ _skills_contains: talentFilter.skills_contains, actionType: "PV" })) ?? [{ _skills_contains: [], actionType: "PV" }];
  };

  const { data, loading } = useQuery(queryKey, {
    variables: {
      orderDirection: sort.toLowerCase() === "recent" ? OrderDirection.Desc : OrderDirection.Asc,
      filterBy: option?.isTalent ? talentFilterSchema() : filteredData,
    },
  });

  const onSelectSort = (sort: string) => {
    localStorage.setItem("jobSorted", sort);
    setSort(sort);
  };

  const onSelectFilters = (filters: FilteredData[] | undefined) => {
    setFilteredData(filters);
  };

  return {
    data,
    loading,
    onSelectSort,
    onSelectFilters,
    sort,
  };
};

export default useFilter;
