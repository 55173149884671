import Container from "../../ui/Container";

const FutureOfWork = () => {
  return (
    <div className="bg-primary text-white text-center py-20 lg:py-24">
      <Container>
        <div className="relative h-full ">
          <div className="gap-y-6 flex relative flex-col-reverse md:flex-col">
            <p className="font-freigeist font-black leading-[1] text-3xl md:text-[70px] xl:text-[100px]">
              Create, the
              <br /> future of Work.
            </p>
            <p className="font-light lg:text-xl">Take your career to new heights.</p>
          </div>
          <div className="absolute flex justify-start w-12 md:w-36 items-start h-12 md:h-36  md:-top-16 left-0 top-0">
            <img src="/mocks/coin_left.png" alt="left coin" className="w-full h-full object-contain" />
          </div>
          <div className="absolute hidden md:flex h-12 lg:h-24 md:h-20 -bottom-10 right-10">
            <img src="/mocks/coin_right.png" alt="left coin" className="w-full h-full object-contain" />
          </div>
          <div className="absolute md:hidden   h-24 md:h-24 top-0 right-0">
            <img src="/mocks/coin_right_m.png" alt="left coin" className="w-full h-full object-contain" />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default FutureOfWork;
