import { GetAllJobsQuery } from "../../__generated__/graphql";
import { useJobs } from "../../contexts/JobsProviders";
import { ethers } from "ethers";

const JobCardComponent = ({ data }: { data: GetAllJobsQuery["actions"][0] }) => {
  const { setJobDetails } = useJobs();
  const addJobDetailsData = () => {
    setJobDetails(data);
  };

  return (
    <div role="button" className="bg-white border border-primary/20 rounded-2xl p-3" onClick={addJobDetailsData}>
      <div className="rounded-2xl h-[10rem] bg-[conic-gradient(at_top,_var(--tw-gradient-stops))] from-[#FF9447] via-[#869CEA] to-primary overflow-hidden">
        {/* <img alt="profile" src="https://res.cloudinary.com/dbhdpelno/image/upload/v1671996988/cld-sample.jpg" className="object-contain w-full h-full" /> */}
      </div>
      <div className="space-y-2">
        <p className="pt-2 text-lg font-medium line-clamp-1">{data.title}</p>
        <div className="flex flex-wrap gap-2">
          {data?.skills.slice(0, 5).map((skill, id) => (
            <p key={id} className="rounded text-[10px] lg:text-xs text-primary px-2 py-1 text-center truncate bg-primary/10">
              {skill}
            </p>
          ))}
        </div>
        <div className="flex py-2 flex-wrap gap-2 items-center justify-between">
          <div className="flex gap-x-3 items-center">
            <div className="p-2 rounded-full bg-primary">
              <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M10.5644 3.66565C10.3061 3.51388 9.97078 3.51388 9.68658 3.66565L7.6713 4.83318L6.3028 5.59412L4.28752 6.76094C4.02922 6.91341 3.69392 6.91341 3.40972 6.76094L1.80813 5.84753C1.54983 5.69577 1.36923 5.41694 1.36923 5.112V3.31059C1.36923 3.00635 1.52393 2.72753 1.80813 2.57506L3.38312 1.68706C3.64212 1.53459 3.97812 1.53459 4.26232 1.68706L5.83731 2.57506C6.09631 2.72753 6.2769 3.00635 6.2769 3.31059V4.47812L7.6454 3.69106V2.52424C7.64691 2.37268 7.60668 2.22368 7.52919 2.09382C7.4517 1.96396 7.33999 1.85834 7.2065 1.78871L4.28752 0.114353C4.02922 -0.0381177 3.69392 -0.0381177 3.40972 0.114353L0.438938 1.78871C0.305445 1.85834 0.193735 1.96396 0.116246 2.09382C0.0387567 2.22368 -0.00147128 2.37268 4.11268e-05 2.52424V5.89835C4.11268e-05 6.20329 0.15474 6.48212 0.438938 6.63459L3.40972 8.30894C3.66802 8.46071 4.00402 8.46071 4.28752 8.30894L6.3028 7.16682L7.6713 6.38047L9.68658 5.23906C9.94488 5.08659 10.2802 5.08659 10.5644 5.23906L12.1401 6.12706C12.3991 6.27882 12.579 6.55765 12.579 6.86259V8.664C12.579 8.96824 12.425 9.24706 12.1401 9.39953L10.5651 10.3129C10.3061 10.4654 9.97008 10.4654 9.68658 10.3129L8.11089 9.42494C7.85189 9.27247 7.6713 8.99365 7.6713 8.68941V7.52188L6.3028 8.30894V9.47577C6.3028 9.78 6.4575 10.0595 6.7417 10.2113L9.71248 11.8856C9.97078 12.0381 10.3061 12.0381 10.5903 11.8856L13.5611 10.2113C13.8194 10.0595 14 9.78071 14 9.47577V6.10165C14.0015 5.9501 13.9612 5.8011 13.8838 5.67124C13.8063 5.54137 13.6946 5.43575 13.5611 5.36612L10.5651 3.66565H10.5644Z"
                  fill="white"
                />
              </svg>
            </div>
            <div>
              <p className="text-primary truncate font-bold">{ethers.utils.formatEther(data.amount.toString())} MATIC</p>
            </div>
          </div>
          <div
            className={`py-1 px-4 text-[10px] font-normal rounded-sm ${
              data.jobType.toLowerCase() === "full time"
                ? "bg-emerald-100 border-[0.5px] border-green-700 text-green-700"
                : "text-orange-400 border-[0.5px] border-orange-400 bg-orange-100"
            }`}
          >
            <p className="max-w-[70px] truncate">{data.jobType}</p>
          </div>
        </div>
        <button className="bg-blue-600 w-full text-sm lg:text-base rounded-md px-4 text-white py-3">View description</button>
      </div>
    </div>
  );
};

export default JobCardComponent;
