import HeroSection from "../components/LandingPage/sections/Hero";
import JoinTrustify from "../components/LandingPage/sections/JoinTrustify";
import FutureOfWork from "../components/LandingPage/sections/FutureOfWork";
import HowDoesItWork from "../components/LandingPage/sections/HowDoesItWork";
import WhyChooseUs from "../components/LandingPage/sections/WhyChooseUs";
import FindTalents from "../components/LandingPage/sections/FindTalent";

function LandingPage() {
  return (
    <div className="space-y-24 pb-20 w-full">
      <HeroSection />
      <FutureOfWork />
      <HowDoesItWork />
      <WhyChooseUs />
      <FindTalents />
      <JoinTrustify />
    </div>
  );
}

export default LandingPage;
