import { BrowserRouter, Route, Routes } from "react-router-dom";
import Profile from "./pages/profile";
import WorkDone from "./pages/profile/Work";
// import Appreciation from "./pages/profile/Appreciation";
import Navbar from "./components/Navbar";
import CreateJob from "./pages/profile/CreateJob";
import { Toaster } from "react-hot-toast";
import JobsProvider from "./contexts/JobsProviders";
import Discover from "./pages/Discover";
import Footer from "./components/Footer";
import Chat from "./pages/profile/chat";
import LandingPage from "./pages/LandingPage";
import CreatedJobs from "./pages/profile/CreatedJobs";
import JobsComponent from "./pages/Jobs";
import FeedbackComponent from "./components/FeedbackComponent";

function App() {
  // const location = useLocation();
  // const background = location.state && location.state.background;
  return (
    <BrowserRouter>
      <JobsProvider>
        <div className="h-full  w-full bg-white font-sofia flex flex-col min-h-screen">
          <div className="flex flex-col grow">
            <Toaster
              position="top-center"
              reverseOrder={false}
              gutter={8}
              containerClassName=""
              containerStyle={{}}
              toastOptions={{
                // Define default options
                className: "",
                duration: 5000,
                style: {
                  background: "#fff",
                  color: "#000",
                },
                // Default options for specific types
                success: {
                  duration: 2000,
                },
              }}
            />
            <Navbar />
            <Routes>
              <Route path="/">
                <Route index element={<LandingPage />} />
                <Route path="jobs" element={<JobsComponent />} />
                <Route path="discover" element={<Discover />} />
              </Route>
              <Route path="/:address/profile" element={<Profile />}>
                <Route index element={<WorkDone />} />
                <Route path="jobs" element={<CreatedJobs />} />
                <Route path="chat" element={<Chat />} />
                <Route path="feedback" element={<FeedbackComponent />} />
              </Route>
              <Route path="/:address/create-job" element={<CreateJob />} />
            </Routes>
          </div>
          <Footer />
        </div>
      </JobsProvider>
    </BrowserRouter>
  );
}

export default App;
