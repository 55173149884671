import { Profile } from "../../../types/profileData";

const ProfileSkills = ({ profile }: { profile: Profile }) => {
  return (
    <>
      <p className="text-black">Skills</p>
      <div className="flex items-center flex-wrap gap-2">
        {profile?._skills.map((skill, index) => (
          <p className="px-3 text-xs truncate  max-w-[100px] md:max-w-full text-primary  py-2 w-fit rounded-sm bg-blue-500/10" key={index}>
            {skill}
          </p>
        ))}
      </div>
    </>
  );
};

export default ProfileSkills;
