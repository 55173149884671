import { useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';

export const useOnMobileView = () => {
  const { width } = useWindowSize();
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    if (width < 1024) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [width]);

  return { isMobile };
};
