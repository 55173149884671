import { CachedConversation } from "@xmtp/react-sdk";
import { useStreamMessage } from "../../lib/web3/xmpt/useStreamMessage";
import { getRelativeTimeLabel } from "../../utils/relativeTime";
import { useAccount } from "wagmi";
import clsx from "clsx";

const MessagesContainer = ({ conversation }: { conversation: CachedConversation }) => {
  const { chatHistory } = useStreamMessage(conversation);
  const { address } = useAccount();

  return (
    <div className="flex flex-col gap-3 mt-2 w-full">
      {/* load the conversation */}
      {chatHistory.map((chat) => {
        return (
          <div key={chat.id} className={`flex flex-col justify-start w-full px-6 py-1 ${chat.senderAddress === address ? "items-end" : "items-start"}`}>
            <p className="text-[10px] text-neutral-500 font-thin">{getRelativeTimeLabel(chat.sentAt)}</p>
            <div
              className={clsx(
                chat.senderAddress === address ? "bg-primary rounded-l-2xl text-white rounded-tr-2xl" : "bg-white rounded-l-2xl text-primary rounded-tr-2xl ",
                "px-4 py-2 font-light",
              )}
            >
              {chat.content}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default MessagesContainer;
