import { Editor } from '@tiptap/react';
import { Bold, Code, CodepenIcon, Heading1, Heading2, Heading3, Heading4, Heading5, Heading6, Italic, List, ListOrdered, Quote, Redo, Strikethrough, Undo } from 'lucide-react';

const TipTapMenuBar = ({ editor }: { editor: Editor }) => {
  return (
    <div className="flex w-full bg-primary-white-shade rounded-md p-2 flex-wrap gap-2">
      <button
        onClick={() => editor.chain().focus().toggleBold().run()}
        disabled={!editor.can().chain().focus().toggleBold().run()}
        className={editor.isActive('bold') ? 'is-active' : ''}
      >
        <Bold strokeWidth={1.2} className="lg:w-6 w-4 h-4 text-primary lg:h-6" />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleItalic().run()}
        disabled={!editor.can().chain().focus().toggleItalic().run()}
        className={editor.isActive('italic') ? 'is-active' : ''}
      >
        <Italic strokeWidth={1.2} className="lg:w-6 w-4 h-4 text-primary lg:h-6" />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleStrike().run()}
        disabled={!editor.can().chain().focus().toggleStrike().run()}
        className={editor.isActive('strike') ? 'is-active' : ''}
      >
        <Strikethrough strokeWidth={1.2} className="lg:w-6 w-4 h-4 text-primary lg:h-6" />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleCode().run()}
        disabled={!editor.can().chain().focus().toggleCode().run()}
        className={editor.isActive('code') ? 'is-active' : ''}
      >
        <Code strokeWidth={1.2} className="lg:w-6 w-4 h-4 text-primary lg:h-6" />
      </button>

      <button onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()} className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}>
        <Heading1 strokeWidth={1.2} className="lg:w-6 w-4 h-4 text-primary lg:h-6" />
      </button>
      <button onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()} className={editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}>
        <Heading2 strokeWidth={1.2} className="lg:w-6 w-4 h-4 text-primary lg:h-6" />
      </button>
      <button onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()} className={editor.isActive('heading', { level: 4 }) ? 'is-active' : ''}>
        <Heading3 strokeWidth={1.2} className="lg:w-6 w-4 h-4 text-primary lg:h-6" />
      </button>
      <button onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()} className={editor.isActive('heading', { level: 5 }) ? 'is-active' : ''}>
        <Heading4 strokeWidth={1.2} className="lg:w-6 w-4 h-4 text-primary lg:h-6" />
      </button>
      <button onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()} className={editor.isActive('heading', { level: 6 }) ? 'is-active' : ''}>
        <Heading5 strokeWidth={1.2} className="lg:w-6 w-4 h-4 text-primary lg:h-6" />
      </button>
      <button onClick={() => editor.chain().focus().toggleBulletList().run()} className={editor.isActive('bulletList') ? 'is-active' : ''}>
        <List strokeWidth={1.2} className="lg:w-6 w-4 h-4 text-primary lg:h-6" />
      </button>
      <button onClick={() => editor.chain().focus().toggleOrderedList().run()} className={editor.isActive('orderedList') ? 'is-active' : ''}>
        <ListOrdered strokeWidth={1.2} className="lg:w-6 w-4 h-4 text-primary lg:h-6" />
      </button>
      <button onClick={() => editor.chain().focus().toggleCodeBlock().run()} className={editor.isActive('codeBlock') ? 'is-active' : ''}>
        <CodepenIcon strokeWidth={1.2} className="lg:w-6 w-4 h-4 text-primary lg:h-6" />
      </button>
      <button onClick={() => editor.chain().focus().toggleBlockquote().run()} className={editor.isActive('blockquote') ? 'is-active' : ''}>
        <Quote strokeWidth={1.2} className="lg:w-6 w-4 h-4 text-primary lg:h-6" />
      </button>
      <button onClick={() => editor.chain().focus().undo().run()} disabled={!editor.can().chain().focus().undo().run()}>
        <Undo strokeWidth={1.2} className="lg:w-6 w-4 h-4 text-primary lg:h-6" />
      </button>
      <button onClick={() => editor.chain().focus().redo().run()} disabled={!editor.can().chain().focus().redo().run()}>
        <Redo strokeWidth={1.2} className="lg:w-6 w-4 h-4 text-primary lg:h-6" />
      </button>
    </div>
  );
};

export default TipTapMenuBar;
