export const filters = [
  {
    name: "Skills",
    data: ["JavaScript", "UI/UX", "Art design", "Graphic Design", "Web developer", "Motion design", "Mobile developer", "Node.js", "Tech Writing", "TypeScript", "English"],
  },
  {
    name: "Time",
    data: ["Part time", "Full time"],
  },
];

export const customFilter = [
  "JavaScript",
  "UI/UX",
  "Art design",
  "Graphic Design",
  "Web developer",
  "Motion design",
  "Mobile developer",
  "Node.js",
  "Tech Writing",
  "TypeScript",
  "English",
];

export const sorts = ["Recent", "Oldest"];
