import React from "react";

const TrustiffyLogo = ({ className }: { className: string }) => {
  return (
    <svg className={className} viewBox="0 0 41 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M36.18 0.75H5.11998C3.88938 0.75 2.70917 1.23886 1.83901 2.10903C0.968837 2.97919 0.47998 4.1594 0.47998 5.39V36.45C0.479978 37.6815 0.968515 38.8628 1.8384 39.7345C2.70828 40.6063 3.88846 41.0974 5.11998 41.1H36.18C37.4132 41.1 38.596 40.6101 39.468 39.738C40.3401 38.866 40.83 37.6833 40.83 36.45V5.39C40.8273 4.15848 40.3363 2.9783 39.4645 2.10842C38.5927 1.23853 37.4115 0.749997 36.18 0.75ZM19 31L18.67 26.09L14.67 30.26L11.67 26.92L15.52 23.17L10.93 23.07L10.82 18.67L18.11 18.76L18.23 23.76L23.17 23.65L23.27 30.88L19 31ZM23.21 23.59L23.1 18.59L18.16 18.7L18 11.42L22.3 11.35L22.63 16.27L26.63 12.09L29.63 15.43L25.77 19.18L30.37 19.29L30.48 23.69L23.21 23.59Z"
        fill="#254CD4"
      />
    </svg>
  );
};

export default TrustiffyLogo;
