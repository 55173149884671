function isFileImage(file: File) {
  return file && file.type.split('/')[0] === 'image';
}

const MAX_IMAGE_SIZE = 3;
export default function isValidImage(image: File, onError?: (error: string) => void): boolean {
  if (!isFileImage(image)) {
    if (onError) onError('The file provided is not an Image');
    return false;
  }
  const fileMb = image.size / 1024 ** 2;
  if (fileMb > MAX_IMAGE_SIZE) {
    if (onError) onError('The file exceeds the maximum size of the 5Mb');
    return false;
  }
  return true;
}
