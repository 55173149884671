import { FaCheckCircle } from "react-icons/fa";
import { VerticalBorderComponent } from "../../ui/HorizontalBorderComponent";
import Container from "../../ui/Container";

const HowDoesItWork = () => {
  return (
    <Container>
      <div className="flex px-4 md:px-0 md:flex-row flex-col gap-y-10 md:gap-y-0 md:gap-x-36 items-stretch">
        <div className="lg:rounded-l-2xl rounded-2xl overflow-hidden flex-1 flex flex-col justify-center items-center bg-[#FFF6F0]">
          <div className="w-full max-w-[500px] lg:max-w-[400px] lg:max-h-[400px] max-h-[500px]">
            <img alt="avatar" src="/mocks/trustiffy_1.png" width={700} height={700} className="w-full h-full object-cover" />
          </div>
        </div>
        <div className="space-y-8 flex-1">
          <p className="text-xl md:text-2xl lg:text-4xl font-freigeist text-primary font-bold">How does it work?</p>
          <div className="flex flex-col">
            <div className="flex items-stretch space-x-4 h-20">
              <div className="flex pt-1 space-y-4 items-center flex-col">
                <div>
                  <FaCheckCircle className="text-primary" />
                </div>
                <VerticalBorderComponent />
              </div>
              <div className="space-y-1">
                <p className="font-medium md:text-xl text-lg lg:text-2xl">Wallet Connect & Setup Metamask </p>
                <p className="text-neutral-600 text-sm font-thin">Connect your Metamask wallet to access Trustiffy services.</p>
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="flex items-stretch space-x-4 h-20">
              <div className="flex pt-1 space-y-4 items-center flex-col">
                <div>
                  <FaCheckCircle className="text-primary" />
                </div>
                <VerticalBorderComponent />
              </div>
              <div className="space-y-1">
                <p className="font-medium md:text-xl text-lg lg:text-2xl"> Create Job</p>
                <p className="text-neutral-600 text-sm font-thin">Fill your job details including the payment amount which is deducted 10% as listing fee.</p>
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="flex items-stretch space-x-4">
              <div className="flex pt-1 space-y-4 items-center flex-col">
                <div>
                  <FaCheckCircle className="text-primary" />
                </div>
              </div>
              <div className="space-y-1">
                <p className="font-medium md:text-xl text-lg lg:text-2xl"> Payment Escrow</p>
                <p className="text-neutral-600 text-sm font-thin">Your payment is secured in a blockchain escrow.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default HowDoesItWork;
