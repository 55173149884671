import { ReactNode, useEffect } from 'react';
import { useWeb3ModalEvents } from '@web3modal/wagmi/react';
import { useAccount } from 'wagmi';
import { TRUSTIFFY_CONNECTED_ACCOUNT } from '../utils/localstorage';

const ConnectListener = ({ children }: { children: ReactNode }) => {
  const { data } = useWeb3ModalEvents();
  const { address } = useAccount();

  useEffect(() => {
    if (data.event === 'DISCONNECT_SUCCESS') {
      localStorage.removeItem(TRUSTIFFY_CONNECTED_ACCOUNT);
    }
  }, [data.event]);

  useEffect(() => {
    if (address) {
      localStorage.setItem(TRUSTIFFY_CONNECTED_ACCOUNT, address);
    }
  }, [address]);

  return <>{children}</>;
};

export default ConnectListener;
