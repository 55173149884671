import JobCardComponent from "../components/Jobs/JobCardComponent";
import Container from "../components/ui/Container";
import Filters from "../components/filter";
import { JobLoadingSkeleton } from "../components/ui/LoadingSkeleton";
import useFilter from "../hook/useFilter";
import { GET_ALL_JOBS } from "../lib/apollo/queries";
import NotFound from "../components/NotFound";

export type FilteredData = {
  skills_contains?: string[];
};
const JobsPage = () => {
  const { data, loading, onSelectFilters, onSelectSort, sort } = useFilter(GET_ALL_JOBS);

  if (loading) {
    return <JobLoadingSkeleton className="mt-5 gap-6 lg:grid-cols-3" />;
  }
  return (
    <div className="pb-12 ">
      <Container>
        <Filters onSelectFilters={onSelectFilters} sortName={sort} onSelectSort={onSelectSort} />
      </Container>
      {!data || data?.actions.length === 0 ? (
        <div className="my-20">
          <NotFound title="No job found" />
        </div>
      ) : (
        <Container className="grid mt-5 gap-6 lg:grid-cols-3 grid-cols-1 ">
          {data.actions.map((element, index) => (
            <JobCardComponent key={index} data={element} />
          ))}
        </Container>
      )}
    </div>
  );
};

export default JobsPage;
