import ErrorBox from "../../ui/ErrorBox";
import { TextareaAutosize } from "@mui/material";
import { Input } from "../../ui/input";
import useVerifyProfile from "../../../lib/web3/trustiffy/hooks/useVerifyProfile";
import { Button } from "../../ui/button";
import { Pen, PenSquare, Pencil } from "lucide-react";
import { GET_USER_PROFILE } from "../../../lib/apollo/queries";
import { useAccount } from "wagmi";
import { useQuery } from "@apollo/client";
import { useState } from "react";
import SideModal from "../../ui/SideModal";
import useClickOutside from "../../../hook/useClickOutSide";

const EditProfileDetails = ({ onClose }: { onClose: () => void }) => {
  const { address: walletAddress } = useAccount();

  const { data, loading } = useQuery(GET_USER_PROFILE, { variables: { userAddress: walletAddress } });
  const profile = data?.userProfileActions[0];
  const { onSubmit, savingToIPFS, fileError, isLoading, onChange, profileData } = useVerifyProfile();
  const isEditProfile = window.location.pathname.includes("/profile");
  const [previewProfileImage, setPreviewProfileImage] = useState<string | null>();
  const [previewCoverImage, setPreviwCoverImage] = useState<string | null>();

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>, imageFor: string) => {
    // @ts-ignore
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    const imageUrl = URL.createObjectURL(file);
    if (imageFor === "cover") {
      setPreviwCoverImage(imageUrl);
    } else {
      setPreviewProfileImage(imageUrl);
    }
  };

  const ref = useClickOutside(onClose);

  return (
    <>
      <SideModal ref={ref} className="bg-primary lg:w-[40%] lg:max-w-[40%]" title={isEditProfile ? "Edit Profile" : "Verify Profile"} onCloseModal={onClose}>
        <form onSubmit={(e) => onSubmit(e, { isUpdate: isEditProfile })} className="flex bg-white pb-6 h-full flex-col gap-2 text-black">
          <div className="relative overflow-hidden  w-full">
            <img src={previewCoverImage ?? "/mocks/coverholder.jpg"} width={200} height={200} alt="cover-holder" loading="lazy" className="h-[7rem] w-full object-cover" />
            <label htmlFor="coverPicture" className="text-gray-400 ">
              <input name="coverPicture" type="file" onChange={(e) => handleImageChange(e, "cover")} className=" w-full opacity-0 absolute overflow-hidden  inset-0 z-[1000]" />
            </label>
            <span className="absolute rounded-full bg-white p-1 top-2 tex-white right-2">
              <Pencil className="fill-primary stroke-primary-white-shade " size={16} />
            </span>
          </div>
          <div>
            <div className="relative w-fit ml-6 -mt-10 z-[2000]">
              <img
                alt=""
                className={`object-cover w-20 h-20 rounded-full ${loading && "bg-primary-shade animate-pulse"}`}
                loading="lazy"
                src={profile?._profileURL ? (previewProfileImage ? previewProfileImage : profile?._profileURL ?? "/mocks/coverholder.jpg") : "/mocks/coverholder.jpg"}
              />

              <span className="absolute rounded-full bg-primary p-1 -bottom-1 tex-white right-1">
                <Pencil className="fill-primary-white-shade stroke-primary " size={16} />
              </span>
              <label htmlFor="profileImage" className="text-gray-400 ">
                <input onChange={(e) => handleImageChange(e, "profile")} name="profileImage" type="file" className=" w-full opacity-0 absolute overflow-hidden  inset-0 z-[1000]" />
              </label>
            </div>
          </div>
          <div className="px-4 grow space-y-5 mt-4">
            <Input name="name" value={profileData.name} onChange={onChange} contentEditable placeholder="Name" className="w-full text-base" required />
            <Input name="skills" value={profileData.skills} onChange={onChange} placeholder="Skills ex: UI/UX,JavaScript,Marketing" className="w-full text-base" required />
            <TextareaAutosize
              value={profileData.bio}
              onChange={onChange}
              maxRows={10}
              name="bio"
              placeholder="Bio"
              className="w-full border-gray-300 min-h-[15rem] border p-3 resize-none"
            />
            <Input name="cvUrl" value={profileData.cvUrl} onChange={onChange} required type="url" placeholder="CV url or Portfolio" className="w-full text-base" />
            {/* <div className="w-full">
            <label htmlFor="profilePicture" className="text-gray-400">
              Profile Image
            </label>
            <Input name="profilePicture" type="file" placeholder="Profile Image" className="w-full" />
          </div> */}
            {/* <div className="w-full">
          <label htmlFor="coverPicture" className="text-gray-400">
            Cover Image
          </label>
          <Input name="coverPicture" type="file" placeholder="Cover image" className="w-full" />
        </div> */}
          </div>
          <div className="px-4">
            <Button disabled={savingToIPFS || isLoading} type="submit" className="bg-primary w-full">
              {savingToIPFS ? "Saving the image..." : isLoading ? "Setting up..." : "Submit"}
            </Button>
            <ErrorBox errorMessage={fileError} />
          </div>
        </form>
      </SideModal>
    </>
  );
};

export default EditProfileDetails;
