import { ReactNode, createContext, useContext } from 'react';
import { ApolloQueryResult, OperationVariables, useQuery } from '@apollo/client';
import { GET_USER_PROFILE } from '../lib/apollo/queries';
import { Profile } from '../types/profileData';
import { useParams } from 'react-router-dom';

type ProfileProviderType = {
  profile: Profile;
  address: string;
  profileLoading: boolean;
  fetchUserProfile: (variables?: Partial<OperationVariables>) => Promise<ApolloQueryResult<any>>;
};
const ProfileContext = createContext<ProfileProviderType>({
  profile: undefined,
  address: '',
  profileLoading: false,
  fetchUserProfile: async () => ({
    data: undefined,
    loading: false,
    networkStatus: 1,
  }),
});
export const useProfileProvider = () => useContext(ProfileContext);

const ProfileProvider = ({ children }: { children: ReactNode }) => {
  const { address } = useParams();
  const { data: profile, loading: profileLoading, refetch: fetchUserProfile } = useQuery(GET_USER_PROFILE, { variables: { userAddress: address } });

  return (
    <ProfileContext.Provider
      value={{
        profile: profile?.userProfileActions[0],
        address: address as string,
        profileLoading,
        fetchUserProfile,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

export default ProfileProvider;
