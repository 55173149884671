export const PROFILE_KEY = 'trustiffy_profile';
export const PROFILE_URL_KEY = 'trustiffy_profile_url';
export const TRUSTIFFY_CONNECTED_ACCOUNT = 'trustiffy_connected_address';
export const UTU_AUTH = 'UTU_AUTH';

class TrustiffyLocalStorage {
  setItem<T>(key: string, value: string) {
    localStorage.setItem(key, value);
    return JSON.parse(value) as T;
  }

  getItem<T>(key: string) {
    const data = localStorage.getItem(key);
    if (!data) return null;
    return JSON.parse(data) as T;
  }
}

const trustiffyLocalStorage = new TrustiffyLocalStorage();
export default trustiffyLocalStorage;
