import EditProfileDetails from "./EditProfileDetails";
import { useEffect } from "react";

const EditProfileModal = ({ onClose, isOpen }: { onClose: () => void; isOpen: boolean }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.className = "overflow-hidden";
    }
    return () => {
      document.body.className = "overflow-auto";
    };
  }, [isOpen]);

  return <EditProfileDetails onClose={onClose} />;
};

export default EditProfileModal;
