import Container from "../../ui/Container";
import SearchInput from "../../ui/SearchInput";
import { Trustiffy3D } from "../Modal";

const HeroSection = () => {
  return (
    <Container className="py-20 flex gap-y-5 lg:gap-y-0 justify-between items-stretch lg:flex-row flex-col-reverse">
      <div className="lg:space-y-4 self-center text-center lg:text-justify flex-1 space-y-6">
        <p className="max-w-xl lg:-ml-2 leading-[1] text-primary text-4xl md:text-[70px] xl:text-[90px] font-freigeist font-black">
          <span className="bg-clip-text text-transparent bg-[linear-gradient(to_right,#FF1D1D,#2D53DA,#2D53DA,#FF1D1D)] bg-[length:200%_auto] animate-gradient repeat-infinite">
            Freelancing{" "}
          </span>
          made easy
        </p>
        <p className="max-w-xl text-neutral-600 font-light">
          Join the web3 revolution and empower your freelancing journey! Enhance trust and transparency within the freelancing ecosystem.
        </p>
        <div className="max-w-lg">
          <SearchInput />
        </div>
      </div>

      <div className="lg:h-[400px] h-[260px] flex items-start justify-start w-full">
        <Trustiffy3D />
      </div>
    </Container>
  );
};

export default HeroSection;
