import Container from "../../ui/Container";
import { BiWorld } from "react-icons/bi";
import { HiBadgeCheck } from "react-icons/hi";

const WhyChooseUs = () => {
  return (
    <Container>
      <div className="flex lg:flex-row flex-col-reverse gap-x-4 items-stretch rounded-xl">
        <div className="space-y-12 lg:basis-3/5 relative z-0  py-12 px-6 md:p-16 rounded-b-xl lg:rounded-br-none lg:rounded-l-xl text-white bg-neutral-900">
          <p className="text-lg md:text-4xl font-freigeist font-bold">Why choose us</p>
          <div className="flex space-y-12 flex-col">
            <div className="flex space-x-4 md:space-x-10 items-start">
              <div>
                <BiWorld size={28} className="mt-1" />
              </div>

              <div className="space-y-1">
                <p className="font-medium md:text-2xl">Wallet Connect & Setup Metamask </p>
                <p className="text-neutral-400 text-sm font-thin">Connect your Metamask wallet to access Trustiffy services.</p>
              </div>
            </div>
            <div className="flex space-x-4 md:space-x-10 items-start">
              <div>
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_608_1868)">
                    <path
                      d="M9.97499 14C8.72666 13.1717 7.34999 12.5184 5.85666 12.1217C7.34999 12.5184 8.72666 13.1717 9.97499 14ZM22.1433 12.1217C20.6383 12.5184 19.2383 13.1834 17.9783 14.035C19.2383 13.1834 20.6383 12.5184 22.1433 12.1217Z"
                      fill="white"
                    />
                    <path
                      d="M18.0717 11.235C17.8617 7.98004 16.5433 4.80671 14.07 2.33337C11.5733 4.83004 10.1967 8.00337 9.92834 11.235C11.4217 12.0284 12.7983 13.055 14 14.3034C15.2017 13.0667 16.5783 12.04 18.0717 11.235ZM10.4883 14.3267C10.325 14.21 10.1383 14.105 9.96334 13.9884C10.1383 14.1167 10.325 14.21 10.4883 14.3267ZM17.9783 14.035C17.8267 14.14 17.6633 14.2217 17.5117 14.3384C17.6633 14.2217 17.8267 14.14 17.9783 14.035ZM14 18.025C11.4917 14.1984 7.21001 11.6667 2.33334 11.6667C2.33334 17.8734 6.25334 23.1234 11.7017 25.0717C12.4367 25.34 13.2067 25.5384 14 25.6667C14.7933 25.5267 15.5517 25.3284 16.2983 25.0717C21.7467 23.1234 25.6667 17.8734 25.6667 11.6667C20.79 11.6667 16.5083 14.1984 14 18.025Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_608_1868">
                      <rect width="28" height="28" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>

              <div className="space-y-1">
                <p className="font-medium md:text-2xl">Create Job </p>
                <p className="text-neutral-400 text-sm font-thin">Fill your job details including the payment amount which is deducted 10% as listing fee.</p>
              </div>
            </div>
            <div className="flex space-x-4 md:space-x-10 items-start">
              <div>
                <HiBadgeCheck size={26} className="mt-1" />
              </div>

              <div className="space-y-1">
                <p className="font-medium md:text-2xl">Safe and secure </p>
                <p className="text-neutral-400 text-sm font-thin">Your payment is secured in a blockchain escrow.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-[#F5F7FF]  w-full lg:h-[500px] h-[400px] md:h-[722px] lg:basis-2/5 flex justify-center items-end  relative rounded-r-xl ">
          <div className=" w-full -bottom-12  lg:-bottom-8 h-[600px] lg:h-[700px]  absolute -scale-x-100">
            <img alt="avatar" src="/mocks/trustiffy_3.png" width={800} height={800} className="w-full h-full object-cover" />
          </div>
        </div>
      </div>
    </Container>
  );
};
export default WhyChooseUs;
