import { UtuAuthData } from "../../../types/UTU";
import tokenExpired from "../../../utils/jwt";
import trustiffyLocalStorage, { UTU_AUTH } from "../../../utils/localstorage";
import toast from "react-hot-toast";

type Entity = {
  walletAddress: string;
  name: string;
};

export default async function saveAsUTUEntity(entity: Entity) {
  const utuAuth = trustiffyLocalStorage.getItem<UtuAuthData>(UTU_AUTH);
  if (!utuAuth) {
    toast.error("Can not save the user as entity, please connect to UTU");
    return;
  }

  if (tokenExpired(utuAuth.access_token)) {
    toast.error("Can not save the user as entity, please connect to UTU");
    return;
  }

  const overrideApiUrl = process.env.REACT_APP_API_URL;
  await fetch(overrideApiUrl + "/core-api-v2/entity", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${utuAuth.access_token}`,
    },
    body: JSON.stringify({
      name: entity.name,
      type: "provider",
      ids: {
        uuid: entity.walletAddress.toLowerCase(),
      },
    }),
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}
