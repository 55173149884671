import { Web3Storage } from 'web3.storage';
import { create } from 'ipfs-http-client';

function getAccessToken() {
  return process.env.REACT_APP_WEB3STORAGE_TOKEN;
}

function makeStorageClient() {
  return new Web3Storage({ token: getAccessToken() as string });
}

export const saveImageWithWeb3Storage = async (file: File[]) => {
  if (!file) return;

  const storageClient = makeStorageClient();
  const fileIdenfier = await storageClient.put(file);
  const retrieveFile = await storageClient.get(fileIdenfier);
  if (!retrieveFile?.ok) throw new Error('Failed to retrieve File');
  const storedFile = await retrieveFile.files();
  return `https://${fileIdenfier}.ipfs.w3s.link/${storedFile[0].name}`;
};

export const upload = async (data: unknown) => {
  const TOKEN = process.env.REACT_APP_INFURA_API_KEY + ':' + process.env.REACT_APP_INFURA_API_SECRET;
  const IPFSclient = create({
    host: 'ipfs.infura.io',
    port: 5001,
    protocol: 'https',
    headers: {
      authorization: 'Basic ' + btoa(TOKEN as string),
    },
  });

  const serialized = JSON.stringify(data);
  const { path } = await IPFSclient.add(serialized);
  return `https://cloudflare-ipfs.com/ipfs/${path}`;
};
