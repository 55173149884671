import { X } from "lucide-react";
import React, { ChangeEvent, useState } from "react";
import SearchInput from "./SearchInput";
import { Link } from "react-router-dom";
import truncateString from "../../utils/trancate";
import { useAccount } from "wagmi";
import { GetAllJobsQuery } from "../../__generated__/graphql";
import SearchResultCard from "./SearchResultCard";

const MobileSidebar = ({
  onCloseModal,
  allJobs,
  onClick,
}: {
  onCloseModal: () => void;
  allJobs: GetAllJobsQuery["actions"] | undefined;
  onClick: (job: GetAllJobsQuery["actions"][0]) => void;
}) => {
  const { isConnected, address } = useAccount();
  const [showSearchResult, setShowSearchResult] = useState(false);
  const [jobResult, setJobResult] = useState<GetAllJobsQuery["actions"] | undefined>();

  const onSearchJob = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.target.value.trim()) {
      setShowSearchResult(true);
    } else {
      setShowSearchResult(false);
    }
    if (!allJobs) return;
    const result = allJobs?.filter((job) => job.title.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()));
    setJobResult(result);
  };

  return (
    <>
      <div className="grow overflow-hidden">
        <div className="flex px-4 text-white bg-primary py-4 items-center justify-end">
          <button onClick={onCloseModal}>
            <X size={24} />
          </button>
        </div>
        <div className="px-4 bg-white py-2">
          <SearchInput onChange={onSearchJob} />
        </div>
        {showSearchResult ? (
          <div className="w-full px-4 pt-4 space-y-4">
            {jobResult?.map((job) => (
              <SearchResultCard onClick={onClick} key={job.id} job={job} />
            ))}
          </div>
        ) : (
          <ul className="px-4 pt-6 flex flex-col gap-y-4">
            {isConnected && (
              <li className="border-b border-primary/20  pb-2">
                <Link onClick={onCloseModal} to={`${address}/create-job`}>
                  Create a Job
                </Link>
              </li>
            )}

            <li className="border-b border-primary/20 pb-2">
              <Link onClick={onCloseModal} to="/jobs">
                Browse Jobs
              </Link>
            </li>
            <li className="border-b border-primary/20  pb-2">
              <Link onClick={onCloseModal} to="/discover">
                Find Talent
              </Link>
            </li>
          </ul>
        )}
      </div>
      <div>
        <div>
          {isConnected && (
            <div className="text-center text-xs text-neutral-500">
              <p>You are connected on</p>
              <p> {truncateString(address as unknown as string)}</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MobileSidebar;
