// TODO: should in the future be splitted into smaller components
import { useJobs } from "../../contexts/JobsProviders";
import { ChangeEvent, useEffect, useState } from "react";
import { freelanceMarketplace } from "../../lib/web3/trustiffy/trustiffyFreelanceMarketplace";
import toast from "react-hot-toast";
import { CgSpinnerTwoAlt } from "react-icons/cg";
import "@ututrust/web-components";
import { Briefcase, Calendar } from "lucide-react";
import { useQuery } from "@apollo/client";
import { GET_JOB_CHOOSEN_FREELANCER, GET_JOB_SUBMISSION, GET_MY_JOB_APPLICATION, GET_SUBMISSION_PAYMENT, GET_USER_PROFILE } from "../../lib/apollo/queries";
import Skills from "./partials/Skills";
import Applicants from "./partials/Applicants";
import SideModal from "../ui/SideModal";
import { useAccount } from "wagmi";
import HorizontalBorderComponent from "../ui/HorizontalBorderComponent";
import { ethers } from "ethers";
import ChoosenFreelancer from "./partials/ChosenFreelancer";
import SendIcon from "../ui/icons/SendIcon";
import StatusIcon from "../ui/icons/StatusIcon";
import { getRelativeTimeLabel } from "../../utils/relativeTime";
import useClickOutside from "../../hook/useClickOutSide";
import VerifyProfileDisclaimer from "../ui/VerifyProfileDisclaimer";

declare global {
  namespace JSX {
    // prevents typescript errors for the tags
    interface IntrinsicElements {
      "x-utt-balance": any;
      "x-utu-app-link": any;
      "x-utu-wallet-disconnect": any;
      "x-utu-root": any;
      "x-utu-recommendation": any;
      "x-utu-feedback-details-popup": any;
      "x-utu-feedback-form-popup": any;
      "utu-feedback-form": any;
    }
  }
}

export const DEFAULT_ADDRESS = "0x0000000000000000000000000000000000000000";

export const JobDetailsModal = ({
  onCloseModel,
  isDisplayedOnProfile = false,
}: {
  openModel?: boolean;
  onCloseModel: () => void;
  isDisplayedOnProfile?: boolean;
  isMobile: boolean;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { address } = useAccount();
  const [submissionProof, setSubmissionProof] = useState("");
  const [chooseFreelanceLoading, setChooseFreelanceLoading] = useState(false);
  const [submitWorkProcessing, setSubmitWorkProcessing] = useState(false);
  const { jobDetails } = useJobs();
  const { data: applications } = useQuery(GET_JOB_CHOOSEN_FREELANCER, { variables: { jobId: jobDetails?.Trustiffy_id } });
  const { data: myApplication, loading: isMyApplicationLoading } = useQuery(GET_MY_JOB_APPLICATION, { variables: { jobId: jobDetails?.Trustiffy_id, walletAddress: address } });
  const { data: jobSumission, loading: jobSumissionLoading } = useQuery(GET_JOB_SUBMISSION, { variables: { jobId: jobDetails?.Trustiffy_id } });
  const { data: payment, loading: paymentLoading } = useQuery(GET_SUBMISSION_PAYMENT, { variables: { jobId: jobDetails?.Trustiffy_id } });
  const { data } = useQuery(GET_USER_PROFILE, { variables: { userAddress: address } });

  // @ts-ignore
  const hasApplied = myApplication?.freelancerApplieds.length > 0 && !isMyApplicationLoading;
  const hasBeenChoosenForTheJob = applications?.freelancerChosens[0] && applications?.freelancerChosens[0].freelancer.toLowerCase() === address?.toLowerCase();
  const hasAChoosenFreelancerAlready = applications?.freelancerChosens && applications?.freelancerChosens.length > 0;
  const hasSubmission = jobSumission?.workSubmitteds[0] && !jobSumissionLoading;
  const isEmployerProfile = jobDetails?.employer.toLowerCase() === address?.toLowerCase();
  const paymentMade = payment?.paymentMades[0] && !paymentLoading;
  const shouldDisplayJobStatus = false;
  const profile = data?.userProfileActions[0];
  const ref = useClickOutside(onCloseModel);

  useEffect(() => {
    if (jobDetails) {
      document.body.className = "overflow-hidden";
    }
    return () => {
      document.body.className = "overflow-auto";
    };
  }, [jobDetails]);

  const applyForJob = async () => {
    if (!jobDetails || !jobDetails.id || !address) {
      return;
    }

    try {
      setIsLoading(true);
      await freelanceMarketplace.applyForJob(Number(jobDetails.Trustiffy_id));
      toast.success("You successfully applied to the job");
    } catch (err) {
      toast.error("Error accured while trying to perform the transaction");
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const chooseTheFreelance = async (applicant: string, jobId: number) => {
    try {
      setChooseFreelanceLoading(true);
      await freelanceMarketplace.chooseFreelance(jobId, applicant);
      toast.success("Freelance choosen successfully");
    } catch (err) {
      console.log(err);
      toast.error("Couldn't choose the freelance");
    } finally {
      setChooseFreelanceLoading(false);
    }
  };

  const workDetailsChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSubmissionProof(e.target.value);
  };

  const submitWork = async () => {
    if (!submissionProof) return;
    try {
      setSubmitWorkProcessing(true);
      await freelanceMarketplace.submitWork(jobDetails?.Trustiffy_id, submissionProof);

      toast.success("Work submitted successfully");
      setSubmissionProof("");
    } catch (err: any) {
      console.error(err);
      if (err.reason === "underlying network changed") {
        toast.error("Wrong network detected, connect to Polygon");
      } else toast.error(err.reason);
    } finally {
      setSubmitWorkProcessing(false);
    }
  };

  const renderJobStatus = (): string => {
    if (hasSubmission && !paymentMade) {
      return "APPROVED";
    } else if (hasSubmission && paymentMade) {
      return "CLOSED";
    } else {
      return "OPENED";
    }
  };

  const renderJobDetailsTitle = () => {
    if (hasBeenChoosenForTheJob) {
      if (hasSubmission) return "Congratulation 🎉 You have made a submission";
      return "Congratulation 🎉 You got the job.";
    }
    return "Apply to Job";
  };

  if (!jobDetails) {
    return <p>Nothing to show</p>;
  }

  return (
    <SideModal ref={ref} title={renderJobDetailsTitle()} onCloseModal={onCloseModel} className="bg-white lg:w-[50%] pb-9 space-y-9 lg:max-w-[50%]">
      <div className="flex items-center justify-between lg:px-10 px-6">
        <p className="lg:text-xl text-lg text-primary line-clamp-1 font-bold">{jobDetails.title}</p>
        {!shouldDisplayJobStatus && (
          <div className="">
            {!isDisplayedOnProfile && (
              <p className="border-green-500/40 borderm flex truncate gap-1 w-fit text-[10px] bg-green-500 text-white rounded-md py-2 px-3">
                <span>
                  <StatusIcon />
                </span>
                Status: {renderJobStatus()}
              </p>
            )}
          </div>
        )}
      </div>

      <div className="px-8 space-y-6 mt-6">
        <div className="prose" dangerouslySetInnerHTML={{ __html: jobDetails.description }} />
        <HorizontalBorderComponent />
        <div className="flex flex-wrap py-4 gap-8 items-center justify-between">
          <div className="flex gap-x-5 items-center">
            <div>
              <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_1233_2065)">
                  <path
                    d="M3.2375 14.475L14.4875 3.225C14.9375 2.775 15.5625 2.5 16.25 2.5H25C26.375 2.5 27.5 3.625 27.5 5V13.75C27.5 14.4375 27.225 15.0625 26.7625 15.525L15.5125 26.775C15.0625 27.225 14.4375 27.5 13.75 27.5C13.0625 27.5 12.4375 27.225 11.9875 26.7625L3.2375 18.0125C2.775 17.5625 2.5 16.9375 2.5 16.25C2.5 15.5625 2.7875 14.925 3.2375 14.475ZM23.125 8.75C24.1625 8.75 25 7.9125 25 6.875C25 5.8375 24.1625 5 23.125 5C22.0875 5 21.25 5.8375 21.25 6.875C21.25 7.9125 22.0875 8.75 23.125 8.75ZM8.4125 19.0875L13.75 24.425L19.0875 19.0875C19.65 18.5125 20 17.7375 20 16.875C20 15.15 18.6 13.75 16.875 13.75C16.0125 13.75 15.225 14.1 14.6625 14.675L13.75 15.575L12.8375 14.6625C12.275 14.1 11.4875 13.75 10.625 13.75C8.9 13.75 7.5 15.15 7.5 16.875C7.5 17.7375 7.85 18.525 8.4125 19.0875Z"
                    fill="#254CD4"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1233_2065">
                    <rect width="30" height="30" fill="white" transform="matrix(-1 0 0 1 30 0)" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div>
              <p className="text-primary font-bold">{ethers.utils.formatEther(jobDetails.amount.toString())} MATIC</p>
              <p className="text-xs">Payement Price</p>
            </div>
          </div>
          <div className="flex items-center gap-x-5">
            <div>
              <Calendar size={30} className="text-primary" />
            </div>
            <div>
              <p className="text-primary font-bold">{getRelativeTimeLabel(Number(jobDetails.duration), { isTimeline: true })}</p>
              <p className="text-xs">Time line</p>
            </div>
          </div>
          <div className="flex items-center gap-x-5">
            <div>
              <Briefcase size={30} className="text-primary" />
            </div>
            <div>
              <p className="text-primary font-bold">{jobDetails.jobType}</p>
              <p className="text-xs">Job type</p>
            </div>
          </div>
        </div>
        <HorizontalBorderComponent />

        <Skills skills={jobDetails?.skills ?? []} />
      </div>

      {address && profile?.verified && (
        <div className="px-8">
          {/* Choose freelancer */}
          {!hasAChoosenFreelancerAlready && (
            <Applicants
              jobDetails={jobDetails}
              chooseFreelanceLoading={chooseFreelanceLoading}
              onChooseFeelancer={(freelancer) => {
                chooseTheFreelance(freelancer, jobDetails.Trustiffy_id);
              }}
            />
          )}

          {hasAChoosenFreelancerAlready && !jobSumissionLoading && (
            <ChoosenFreelancer
              onSendMessage={onCloseModel}
              canPayFreelancer={(isEmployerProfile && hasSubmission) ?? false}
              jobDetails={jobDetails}
              submission={jobSumission?.workSubmitteds[0]?.submission}
            />
          )}

          {/* Apply to a job */}
          {jobDetails.employer.toLowerCase() !== address?.toLowerCase() && !isDisplayedOnProfile && (
            <div className="w-full px-6 lg:px-10 mt-5">
              {!hasApplied ? (
                <div className="flex items-center gap-x-3">
                  <button onClick={onCloseModel} className="border border-primary rounded-md px-6 py-2 text-primary">
                    Cancel
                  </button>
                  <button onClick={applyForJob} disabled={isLoading} className="bg-primary flex-1 rounded-md mx-auto  flex justify-center items-center text-white px-6 my-8 py-2">
                    {!isLoading ? "Apply to this Job" : <CgSpinnerTwoAlt className="text-xl text-white animate-spin" />}
                  </button>
                </div>
              ) : (
                <p className="text-center w-full text-primary"> {hasBeenChoosenForTheJob ? renderJobDetailsTitle() : "You have applied already."}</p>
              )}
            </div>
          )}

          {/* Submit the work */}
          {/*//  TODO: isDisplayedOnProfile check should be removed one the choosen freelancer is added to the jobs coming from the smart contract  */}
          {hasBeenChoosenForTheJob && !isDisplayedOnProfile && !hasSubmission && (
            <div className="text-xl font-bold mt-10">
              <h3 className="text-primary font-freigeist font-black text-2xl">Project Completed? Submit Now.</h3>
              <div className="mt-4">
                <div className="items-center flex gap-2">
                  <input
                    type="text"
                    onChange={workDetailsChange}
                    value={submissionProof}
                    name="uri"
                    className="border rounded-lg border-gray-200 bg-primary-white-shade text-primary placeholder:text-primary-shade font-normal placeholder:font-thin w-full outline-none py-5 px-7 text-sm"
                    placeholder="Enter the work proof"
                  />
                  <button disabled={submitWorkProcessing} onClick={submitWork} className="flex items-center justify-center py-4 px-6 bg-primary text-white rounded-lg font-normal">
                    {submitWorkProcessing ? <CgSpinnerTwoAlt className="text-xl text-white animate-spin" /> : <SendIcon />}
                  </button>
                </div>
                <x-utu-root source-uuid={(address as string).toLowerCase()} target-type="provider" className="!w-full">
                  <x-utu-feedback-form-popup
                    source-uuid={applications?.freelancerChosens[0].freelancer.toLowerCase()}
                    target-uuid={jobDetails.employer.toLowerCase()}
                    transaction-id={5}
                  />
                </x-utu-root>
              </div>
            </div>
          )}
        </div>
      )}
    </SideModal>
  );
};
