import FilterModalDropDown from "./FilterModalDropDown";
import { useState } from "react";
import SortingFilter from "./partials/SortingFilter";
import { Filter, ListFilter } from "lucide-react";
import { RiArrowDownSFill } from "react-icons/ri";
import clsx from "clsx";
import { FilteredData } from "../../pages/Jobs";

type TFilters = {
  onSelectSort: (sortName: string) => void;
  sortName: string;
  onSelectFilters: (filters: FilteredData[] | undefined) => void;
  withSort?: boolean;
};

const Filters = ({ onSelectSort, sortName, onSelectFilters, withSort = true }: TFilters) => {
  const [filterModal, setFilterModal] = useState(false);
  const [filterDropDown, setFilterDropDown] = useState(false);
  const onToggleFilterModal = () => setFilterModal((prev) => !prev);

  return (
    <div className="relative">
      <div className="mt-6 mb-12 flex items-center gap-x-4">
        <button onClick={onToggleFilterModal} className="bg-white px-4 py-3 lg:py-4 lg:px-6 flex items-center justify-center gap-x-3 rounded-md border border-primary-shade">
          <Filter size={20} />
          <span className="font-medium text-primary -mb-1 leading-[0.75rem]">Filters</span>
        </button>
        {withSort && (
          <button
            onClick={() => setFilterDropDown((prev) => !prev)}
            className="bg-white lg:py-4 relative px-4 py-3 lg:px-6 flex items-center justify-center gap-x-3 rounded-md border border-primary-shade"
          >
            <ListFilter size={20} />
            <span className="font-medium text-primary leading-[0.75rem] -mb-1">{sortName}</span>
            <RiArrowDownSFill className={clsx(filterDropDown ? "rotate-180" : "-rotation-180", "transition-all w-5 h-5 ")} />
            {filterDropDown && <SortingFilter onSelectSort={onSelectSort} />}
          </button>
        )}
      </div>
      {filterModal && <FilterModalDropDown onSelectFilters={onSelectFilters} filterModal={filterModal} onClose={onToggleFilterModal} />}
    </div>
  );
};

export default Filters;
