import { saveImageWithWeb3Storage } from '../../web3Storage';
import isValidImage from '../../../../utils/imageValidator';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { trustiffyProfile } from '../trustiffyProfile';
import { useAccount } from 'wagmi';
import { useQuery } from '@apollo/client';
import { GET_USER_PROFILE } from '../../../apollo/queries';
import saveAsUTUEntity from '../../UTU/SaveAsEntity';

type ProfileDataInput = {
  name: string;
  bio: string;
  coverURL: string;
  profileURL: string;
  skills: string;
  cvUrl: string;
};

export default function useVerifyProfile() {
  const [fileError, setFileError] = useState('');
  const [savingToIPFS, setSavingToIPFS] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { address } = useAccount();
  const { data, refetch: fetchUserProfile } = useQuery(GET_USER_PROFILE, { variables: { userAddress: address } });
  const profile = data?.userProfileActions[0];
  const [profileData, setProfileData] = useState<ProfileDataInput>({
    name: '',
    bio: '',
    coverURL: '',
    profileURL: '',
    skills: '',
    cvUrl: '',
  });

  useEffect(() => {
    if (profile) {
      setProfileData({
        bio: profile?._bio ?? '',
        coverURL: profile?._coverURL ?? '',
        name: profile?.name ?? '',
        profileURL: profile?._profileURL ?? '',
        skills: (profile?._skills?.join(',') as string) ?? '',
        cvUrl: profile?.cvMetadataURI ?? '',
      });
    }
  }, [profile]);

  const onChange = (e: any) => {
    setProfileData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  async function onSubmit(event: React.FormEvent<HTMLFormElement>, { isUpdate = false }: { isUpdate?: boolean }) {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const name = (formData.get('name') as string) || profile?.name || '';
    const bio = (formData.get('bio') as string) || profile?._bio || '';
    const skills = (formData.get('skills') as string) || profile?._skills.join(',') || '';
    const cvUrl = (formData.get('cvUrl') as string) || profile?.cvMetadataURI || '';

    const coverPicture = formData.get('coverPicture') as File;
    const profilePicture = formData.get('profilePicture') as File;

    let coverUrl = '';
    let profileUrl = '';
    if (coverPicture.size > 0 && profilePicture.size > 0) {
      if (!isValidImage(coverPicture, (e) => setFileError(e))) return;
      setSavingToIPFS(true);
      const urls = (await Promise.all([saveImageWithWeb3Storage([coverPicture]), saveImageWithWeb3Storage([profilePicture])])) as string[];
      coverUrl = urls[0];
      profileUrl = urls[1];
      setSavingToIPFS(false);
    }
    const formattedSkills = (skills || profileData.skills)
      .trim()
      .split(',')
      .map((el) => el.trim());

    setIsLoading(true);
    try {
      if (isUpdate) {
        await trustiffyProfile.updateProfile(name, bio, profileUrl || (profile?._profileURL as string), coverUrl || (profile?._profileURL as string), formattedSkills, cvUrl);
      } else {
        await trustiffyProfile.verifyProfile(name, bio, profileUrl, coverUrl, formattedSkills, cvUrl);
      }
      // save profile as entity
      await saveAsUTUEntity({ name, walletAddress: address as string });
      await fetchUserProfile({ userAddress: address });
      setProfileData({
        bio: '',
        coverURL: '',
        name: '',
        profileURL: '',
        skills: '',
        cvUrl: '',
      });
      toast.success('Profile updated successfully');
    } catch (e: any) {
      toast.error('An error occurred while updating');
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }

  return {
    onSubmit,
    fileError,
    isLoading,
    savingToIPFS,
    onChange,
    profileData,
  };
}
