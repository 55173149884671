import { BsLinkedin, BsTelegram } from "react-icons/bs";
import { Link } from "react-router-dom";
import { RiTwitterXLine } from "react-icons/ri";
import Container from "./ui/Container";

const Footer = () => {
  return (
    <div>
      <div className="pt-12 rounded-b-[50px] lg:rounded-b-[100px] lg:max-h-[24rem] xl:max-h-[26rem] overflow-hidden bg-primary shadow-[inset_-12px_-8px_40px_#46464620] flex pb-6 flex-col space-y-6">
        <Container className="grid font-light text-white lg:grid-cols-4 md:grid-cols-2 grid-cols-1 justify-around item-center gap-6 md:gap-10">
          <div className="flex  flex-col gap-y-2 item-center">
            <Link to="#">About us</Link>
            <Link to="#">Feedback</Link>
            <Link to="#">Community</Link>
          </div>
          <div className="flex flex-col gap-y-2 item-center">
            <Link to="#">Trust, Safety & Security</Link>
            <Link to="#">Help & Support</Link>
            <Link to="#">Trustiffy Foundation</Link>
          </div>

          <div className="flex flex-col gap-y-2 item-center">
            <Link to="#">Term of Service</Link>
            <Link to="#">Private Policy</Link>
            <Link to="#">Notice at Collection</Link>
          </div>
          <div className="flex flex-col gap-y-2 item-center">
            <Link to="#">Accessibility</Link>
            <Link to="#">Cookie policy</Link>
            <Link to="#">Enterprise Solution</Link>
            <Link to="#">Jobs</Link>
          </div>
        </Container>
        <div className="flex items-start relative justify-center w-full">
          {/* <div className="absolute opacity-50 overflow-visible border border-red-500 left-32 blur-2xl">
          </div> */}
          <p className="lg:text-[200px] lg:mt-3 xl:-mt-12 font-freigeist xl:text-[320px] tracking-wider hidden lg:block text-white font-black">Trustiffy</p>
          <div
            className="absolute hidden h-[8rem] lg:flex  xl:h-[14rem] blur-xl bottom-0 bg-gradient-to-r from-primary via-primary/20 to-primary overflow-visible w-full
           "
          />
        </div>
      </div>
      <Container className="flex py-5 justify-between flex-col-reverse gap-y-4 lg:flex-row items-center">
        <p className="text-xs lg:text-sm border-t border-neutral-500/30 text-neutral-500 lg:border-none  w-full lg:w-fit text-center py-3 lg:py-0">
          © 2023 - 2023 <span className="font-bold text-neutral-600"> Trustiffy</span>
        </p>
        <div className="flex item-center">
          <div className="flex gap-x-5 flex-1 item-center">
            <p className="self-center">Follow Us</p>
            <div className="flex  items-center gap-x-3">
              <a href="https://twitter.com/trustiffydapp" className="bg-primary text-white rounded-full w-9 h-9 flex text-xl items-center justify-center">
                <RiTwitterXLine size={20} />
              </a>
              <a href="https://www.linkedin.com/company/trustiffy/about/" className="bg-primary text-white rounded-full w-9 h-9 flex items-center justify-center">
                <BsLinkedin />
              </a>
              <a href="https://t.me/+6LZFcOJvV8BkNjk0" className="bg-primary text-white rounded-full w-9 h-9 flex items-center justify-center">
                <BsTelegram />
              </a>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
