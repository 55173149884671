import { useQuery } from "@apollo/client";
import { GET_ALL_JOBS } from "../../lib/apollo/queries";
import { GetAllJobsQuery } from "../../__generated__/graphql";
import useClickOutside from "../../hook/useClickOutSide";
import SearchResultCard from "../ui/SearchResultCard";

type TJobSearchResultModal = {
  jobSearchResult: GetAllJobsQuery["actions"];
  onClick: (job: GetAllJobsQuery["actions"][0]) => void;
  onCloseModal: () => void;
};

const JobSearchResultModal = ({ jobSearchResult, onClick, onCloseModal }: TJobSearchResultModal) => {
  const { data: jobs } = useQuery(GET_ALL_JOBS);
  const ref = useClickOutside(onCloseModal);

  return (
    <div className="fixed h-screen z-[1] w-full top-30 bg-black/20 backdrop-blur-md flex justify-center">
      <div ref={ref} className="w-full z-[200] mx-5 md:w-[50%] bg-white rounded-lg h-fit py-5 mt-20">
        {jobSearchResult.length > 0 ? (
          jobSearchResult.map((job, index) => {
            return (
              <div key={`job-card-${index}`} className="border-b-[0.5px] cursor-pointer border-primary-white-shade">
                <SearchResultCard job={job} onClick={onClick} />
              </div>
            );
          })
        ) : (
          <p className="px-5">
            No result found
            {jobSearchResult.length > 0 ? <span className="font-medium">, can you try with ({jobs?.actions[0].title})</span> : null}
          </p>
        )}
      </div>
    </div>
  );
};

export default JobSearchResultModal;
