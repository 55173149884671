import React from "react";
import Container from "./Container";
import { RiMedalFill } from "react-icons/ri";
import { clsx } from "clsx";

const CardComponent = () => {
  return (
    <div className="md:bg-[#f3f2f2] shadow-2xl border  border-primary/10 p-3 relative isolate space-y-5 overflow-hidden bg-white/5 shadow-black/5 before:absolute before:inset-0 before:-translate-x-full before:animate-[shimmer_2s_infinite] before:border-t before:border-gray-100/10 before:bg-gradient-to-r before:from-transparent before:via-gray-500/10 before:to-transparent w-full md:max-w-[500px] md:h-full flex flex-col rounded-3xl">
      <div className="rounded-2xl h-[10rem] bg-neutral-400/25 overflow-hidden">
        {/* <img alt="profile" src="https://res.cloudinary.com/dbhdpelno/image/upload/v1671996988/cld-sample.jpg" className="object-contain w-full h-full" /> */}
      </div>
      <div className="space-y-2">
        <p className="pt-2 bg-neutral-400/25 h-3 w-16 rounded-lg"></p>
        <div className="flex flex-wrap gap-2"></div>
        <div className="flex py-2 items-center justify-between">
          <div className="flex gap-x-3 items-center">
            <div className="p-2 w-6 h-6 rounded-full  bg-neutral-400/25"></div>
            <div>
              <p className=" bg-neutral-400/25 h-2 w-12 rounded-lg font-bold"></p>
            </div>
          </div>
          <div>
            <p className=" bg-neutral-400/25 h-2 w-12 rounded-lg font-bold"></p>
          </div>
        </div>
        <button className="bg-neutral-400/25 w-full text-sm lg:text-base rounded-md px-4 text-white py-4"></button>
      </div>
    </div>
  );
};

const TalentCardComponent = () => {
  return (
    <div className="md:bg-[#f3f2f2] shadow-xl border  border-primary/10 p-3 relative isolate space-y-5 overflow-hidden bg-white/5 shadow-black/5 before:absolute before:inset-0 before:-translate-x-full before:animate-[shimmer_2s_infinite] before:border-t before:border-gray-100/10 before:bg-gradient-to-r before:from-transparent before:via-gray-500/10 before:to-transparent w-full md:max-w-[500px] md:h-full flex flex-col rounded-3xl">
      <div>
        <div className="relative overflow-hidden rounded-xl h-28 bg-neutral-400/25 w-full"></div>
        <div className="mx-auto  z-[2000] bg-neutral-400/25  -mt-10 w-16 max-w-[64px] max-h-[64px] h-16  rounded-full"></div>
      </div>

      <div className="space-y-2 lg:space-y-3">
        <div className="flex justify-center">
          <p className="h-3  rounded-lg bg-neutral-400/25 w-20" />
        </div>

        <div className="">
          <div className="flex gap-x-3 line-clamp-1 ">
            {Array(3)
              .fill({})
              .map((_, index) => (
                <p className="h-2 rounded-lg bg-neutral-400/25 w-14" key={index}></p>
              ))}
          </div>
        </div>
        {/* <p className="bg-primary/10 py-1 text-sm max-w-[150px] md:max-w-full truncate px-4 rounded-sm">{data._na}</p> */}
        <div className="flex text-sm  items-center justify-between">
          <div className="flex bg-green-400/20 rounded-sm items-center px-2 py-1 gap-x-3">
            <p className="h-2 rounded-lg bg-neutral-400/25 w-14" />
          </div>
          {/* TODO: will be replaced by reputations */}
          {/* <div className="flex gap-x-1  items-center">
              <span>
                <RiThumbUpFill className="text-primary w-3 h-3 md:w-6 md:h-6" strokeWidth={1.5} />
              </span>
              <p>200</p>
            </div> */}
          <div className="flex w-24 bg-neutral-400/25 rounded-md text-white items-center px-2 py-1 gap-x-3">
            <div>
              <p className="w-4 bg-neutral-400/25 rounded-full h-4"></p>
            </div>
            <p className="text-sm h-2 w-10 bg-neutral-400/25"></p>
            <p className="w-2 bg-neutral-400/25 rounded-full h-2"></p>
          </div>
        </div>
        <div>
          <button className="w-full text-xs md:text-base truncate px-3 rounded-md bg-neutral-400/25 py-4"></button>
        </div>
      </div>
    </div>
  );
};

// Array(5)
//               .fill({})
//               .map((item, index) => (

export const JobLoadingSkeleton = ({ className }: { className: string }) => {
  return (
    <Container>
      <div className={clsx(className, "grid  pb-12  grid-cols-1")}>
        {Array(3)
          .fill({})
          .map((_, index) => (
            <CardComponent key={index} />
          ))}
      </div>
    </Container>
  );
};

export const TalentLoadingSkeleton = () => {
  return (
    <Container>
      <div className="grid mt-5 pb-12 gap-6 lg:grid-cols-3 grid-cols-1">
        {Array(3)
          .fill({})
          .map((_, index) => (
            <TalentCardComponent key={index} />
          ))}
      </div>
    </Container>
  );
};
