import { Link, useParams } from "react-router-dom";
import Container from "../../components/ui/Container";
import JobCardComponent from "../../components/Jobs/JobCardComponent";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { useQuery } from "@apollo/client";
import { GET_ALL_MY_JOBS } from "../../lib/apollo/queries";
import { useAccount } from "wagmi";
import { JobLoadingSkeleton } from "../../components/ui/LoadingSkeleton";
import { Smile } from "lucide-react";

const CreatedJobs = () => {
  const { address } = useParams();
  const { address: signedAddress } = useAccount();
  const { data: myJobs, loading } = useQuery(GET_ALL_MY_JOBS, { variables: { walletAddress: address?.toLowerCase() } });

  const checkIfJobExist = myJobs && myJobs.actions.length === 0 ? false : true;

  return (
    <div>
      {loading ? (
        <JobLoadingSkeleton className="mt-5 gap-6 lg:grid-cols-2" />
      ) : (
        <Container className="grid mt-5 gap-6 !px-0 grid-cols-1 lg:grid-cols-2">
          {signedAddress && address?.toLowerCase() === (signedAddress as string).toLowerCase() ? (
            <PostNewJobButton jobsExists={checkIfJobExist} address={address as string} />
          ) : (
            !checkIfJobExist && <p className="text-primary">No Job</p>
          )}
          {myJobs?.actions.map((element, index) => (
            <JobCardComponent key={index} data={element} />
          ))}
        </Container>
      )}
    </div>
  );
};

export default CreatedJobs;

const PostNewJobButton = ({ address, jobsExists }: { address: string; jobsExists: boolean }) => {
  return (
    <Link to={`/${address}/create-job`} className="border w-full flex items-center justify-center bg-primary border-neutral-400/30 rounded-lg">
      <div className="flex justify-center items-center pt-10 pb-6 gap-y-2 flex-col">
        {!jobsExists ? (
          <span className="flex text-white items-center flex-col gap-y-4">
            <Smile className="w-10 h-10 md:w-12 md:h-12" />
            <span>No Job Posted yet</span>
          </span>
        ) : (
          <span className="flex text-white items-center flex-col gap-y-4">
            <BsFillPlusCircleFill className="w-10 h-10 md:w-12 md:h-12" />
            <span>Add a new job</span>
          </span>
        )}

        {address && (
          <Link to={`/${address}/create-job`} className="text-white">
            <button className="px-4 py-3 bg-white rounded-md text-primary text-sm">Post Job</button>
          </Link>
        )}
      </div>
    </Link>
  );
};
