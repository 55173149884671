import { NavLink, Outlet, useLocation, useParams } from "react-router-dom";
import DeskProfileView, { MobileProfileView } from "../../components/profile/DeskProfileView";
import { useAccount } from "wagmi";
import clsx from "clsx";
import Container from "../../components/ui/Container";
import { MessageSquare, MessageSquarePlus, MessagesSquare } from "lucide-react";
import { BsFillArrowUpRightCircleFill } from "react-icons/bs";
import HorizontalBorderComponent from "../../components/ui/HorizontalBorderComponent";
const Profile = () => {
  const { address: walletAddress } = useAccount();
  const { address } = useParams();
  let { pathname } = useLocation();

  return (
    <div className="bg-background-white-shade pb-20">
      <div className="h-24 lg:h-44 ">
        <img src="/mocks/coverholder.jpg" className="bg-cover h-full w-full" alt="cover" />
      </div>
      <Container className="flex gap-x-10 lg:flex-row flex-col w-full">
        <div className="-mt-64  hidden lg:flex">
          <DeskProfileView />
        </div>
        <div className="lg:hidden flex w-full">
          <MobileProfileView />
        </div>
        <div className="mt-10 flex-col space-y-5 md:space-y-8 w-full">
          <div className="flex max-w-full h-fit no-scrollbar overflow-x-scroll items-center gap-x-3">
            <NavLink
              to="jobs"
              className={clsx(
                pathname.endsWith("jobs") ? "bg-primary/90 text-white" : "bg-primary/10 text-primary",
                "py-2 px-4 flex items-center gap-x-2 rounded-md w-fit flex-nowrap",
              )}
            >
              <span>
                <BsFillArrowUpRightCircleFill />
              </span>
              <span className="truncate">Posted Jobs</span>
            </NavLink>
            <NavLink
              to="chat"
              end
              className={clsx(
                pathname.endsWith("chat") ? "bg-primary/90 text-white" : "bg-primary/10 text-primary",
                "py-2 px-4 rounded-md flex items-center flex-nowrap gap-x-2 shadow-sm",
              )}
            >
              <span>
                <MessagesSquare />
              </span>
              <span>Chat</span>
            </NavLink>
            {walletAddress !== address && (
              <NavLink
                to="feedback"
                end
                className={clsx(pathname.endsWith("feedback") ? "bg-primary/90 text-white" : "bg-primary/10 text-primary", "py-2 px-4 flex items-center gap-x-2  rounded-md")}
              >
                <span>
                  <MessageSquarePlus />
                </span>
                <span className="truncate"> Give feedback</span>
              </NavLink>
            )}
          </div>
          <HorizontalBorderComponent />
          <div className="relative">
            <Outlet />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Profile;
