import JobCardComponent from "../../components/Jobs/JobCardComponent";
import { useJobs } from "../../contexts/JobsProviders";
import Container from "../../components/ui/Container";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_ALL_MY_JOBS } from "../../lib/apollo/queries";

const Work = () => {
  const { address } = useParams();
  const { data: jobs, loading } = useQuery(GET_ALL_MY_JOBS, { variables: { walletAddress: address?.toLowerCase() } });

  if (loading) return null;
  const myJobs = jobs?.actions;
  return (
    <div>
      {myJobs && myJobs.length > 0 ? (
        <Container className="grid mt-5 gap-6 !px-0 xl:grid-cols-4 lg:grid-cols-3 grid-cols-1 2xl:grid-cols-4">
          <>
            {myJobs?.map((element, index) => (
              <JobCardComponent key={index} data={element} />
            ))}
          </>
        </Container>
      ) : (
        !loading && (
          <span>
            You don't have any work done yet, please{" "}
            <Link to="../jobs" className="underline text-primary">
              find a job
            </Link>
          </span>
        )
      )}
    </div>
  );
};

export default Work;
