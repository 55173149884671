import clsx from "clsx";
import { Link, useLocation } from "react-router-dom";
import { MenuIcon } from "lucide-react";
// import debounce from 'lodash.debounce';

//@ts-ignore
import { addressSignatureVerification } from "@ututrust/web-components";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useAccount } from "wagmi";
import { ethers } from "ethers";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useJobs } from "../contexts/JobsProviders";
import JobSearchResultModal from "./modals/JobSearchResultModal";
import { GetAllJobsQuery } from "../__generated__/graphql";
import { useQuery } from "@apollo/client";
import { GET_ALL_JOBS, GET_USER_PROFILE } from "../lib/apollo/queries";
import { trendingData } from "../lib/data/talent-dataset";
import TrustiffyLogo from "./ui/TrustiffyLogo";
import SearchInput from "./ui/SearchInput";
import Container from "./ui/Container";
import { useOnMobileView } from "../helper/isMobile";
import MobileSidebar from "./ui/MobileSidebar";
import MobileModal from "./modals/MobileModal";
import { JobDetailsModal } from "./modals/JobDetailsModal";
import NavbarProfile from "./ui/NavbarProfile";
import trustiffyLocalStorage, { UTU_AUTH } from "../utils/localstorage";
import tokenExpired from "../utils/jwt";
import { UtuAuthData } from "../types/UTU";
import { isUTUConnected } from "../utils/utu";

const navItems: {
  [key: string]: {
    name: string;
  };
} = {
  "/discover": {
    name: "Find Talent",
  },
  "/jobs": {
    name: "Browse Jobs",
  },
};
export const OFFERS = trendingData;

function Navbar() {
  const { data: jobs, loading } = useQuery(GET_ALL_JOBS);
  const { isMobile } = useOnMobileView();
  const { pathname } = useLocation();
  const [hasToken, setHasToken] = useState(false);
  const { open } = useWeb3Modal();
  const overrideApiUrl = process.env.REACT_APP_API_URL;
  const { isConnected, address } = useAccount();
  const { jobDetails, setJobDetails } = useJobs();
  const [jobResult, setJobResult] = useState<GetAllJobsQuery["actions"]>([]);
  const [searchModalOpened, setSearchModalOpened] = useState(false);
  const { data, loading: isLoading } = useQuery(GET_USER_PROFILE, { variables: { userAddress: address } });
  const profile = data?.userProfileActions[0];

  const [openMenu, setOpenMenu] = useState(false);

  const toggleCloseMobileSidebarModal = () => setOpenMenu((prev) => !prev);

  const triggerUtuIdentityDataSDKEvent = (identityData: any): void => {
    const event = new CustomEvent("utuIdentityDataReady", {
      detail: identityData,
    });
    window.dispatchEvent(event);
  };

  const onConnectToWalletClick = async () => {
    await open();
  };

  const initEntity = useCallback(
    async (data: any, offer: any) => {
      await fetch(overrideApiUrl + "/core-api-v2/entity", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${data.access_token}`,
        },
        body: JSON.stringify({
          name: offer.id,
          type: "provider",
          ids: {
            uuid: ethers.utils
              .id(offer.id)
              .slice(0, 40 + 2)
              .toLowerCase(),
          },
        }),
      })
        .then((res) => {
          return res;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    [overrideApiUrl],
  );

  const onConnectToUtuClick = useCallback(async () => {
    // This passes the wallet provider to the SDK so it can do its magic
    // It effectively logs into the UTU Trust Network services and you get a response object back
    // which encapsulates the successful log in.  Among other things it contains the JWT Token.
    const authDataResponse = (await addressSignatureVerification(overrideApiUrl)) as UtuAuthData;
    // overrideApiUrl

    // This instructs the GUI that it can show the Recommendations, show feedback and give feedback
    // screens.
    if (authDataResponse) {
      setHasToken(true);
      localStorage.setItem(UTU_AUTH, JSON.stringify(authDataResponse));
    }

    // this passes the JWT token info to all parts of the SDK. Expect this SDK method to be
    // refactored into the SDK addressSignatureVerification in later versions of the SDK.
    triggerUtuIdentityDataSDKEvent(authDataResponse);
  }, [initEntity]);

  const onSearchJob = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.target.value.trim()) {
      setSearchModalOpened(true);
    } else {
      setSearchModalOpened(false);
    }
    // TODO: may need to be debounced
    if (!jobs) return;

    const result = jobs?.actions.filter((job: { title: string }) => job.title.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()));
    setJobResult(result);
  };

  useEffect(() => {
    const utuAuth = trustiffyLocalStorage.getItem<UtuAuthData>(UTU_AUTH);
    if (!utuAuth) {
      setHasToken(false);
    } else {
      setHasToken(!tokenExpired(utuAuth.access_token));
    }
  }, []);

  return (
    <>
      {searchModalOpened && (
        <JobSearchResultModal
          onCloseModal={() => setSearchModalOpened(false)}
          onClick={(job) => {
            setJobDetails(job);
            setSearchModalOpened(false);
          }}
          jobSearchResult={jobResult}
        />
      )}
      {jobDetails && (
        <JobDetailsModal
          isMobile={isMobile}
          onCloseModel={() => {
            setJobDetails(undefined);
          }}
        />
      )}
      {isMobile && openMenu && (
        <MobileModal isModalOpen={openMenu} onClose={toggleCloseMobileSidebarModal}>
          {!loading && (
            <MobileSidebar
              onClick={(job) => {
                setJobDetails(job);
                setOpenMenu(false);
              }}
              allJobs={jobs?.actions}
              onCloseModal={toggleCloseMobileSidebarModal}
            />
          )}
        </MobileModal>
      )}

      <div className="sticky z-[1] bg-white border-b border-background-white-shade top-0 py-4">
        <Container className=" w-full text-primary gap-x-5 items-center flex justify-between">
          <div className="flex items-center gap-x-10 flex-1">
            <Link to="/" className="flex items-center gap-x-1">
              <TrustiffyLogo className="w-6 h-6" />
              <p className="font-freigeist text-primary font-black text-lg">Trustiffy.</p>
            </Link>
            {!pathname.endsWith("/") && (
              <div className={`lg:flex flex-initial w-max xl:w-2/4 hidden ${searchModalOpened && "z-50"}`}>
                <SearchInput onChange={onSearchJob} />
              </div>
            )}
          </div>

          <div className="lg:flex hidden flex-1 gap-2 justify-end  items-center">
            <div className={`gap-x-5 md:flex mr-6 h-full hidden  font-medium`}>
              {isConnected && !loading && profile?.verified && (
                <Link
                  to={`${address}/create-job`}
                  className={clsx(
                    !pathname.endsWith("create-job") ? null : "before:absolute before:-bottom-7 before:h-1 before:w-14 before:bg-primary before:rounded-t-lg",
                    "relative text-sm shrink-0",
                  )}
                >
                  Post a Job
                </Link>
              )}

              {Object.entries(navItems).map(([path, { name }]) => {
                const isActive = path === pathname;
                return (
                  <Link
                    key={path}
                    to={path}
                    className={clsx(
                      !isActive ? null : " before:absolute before:-bottom-7 before:h-1 before:w-14 before:bg-primary before:rounded-t-lg",
                      "relative text-sm shrink-0",
                    )}
                  >
                    {name}
                  </Link>
                );
              })}
            </div>
            <div>
              {!isConnected && (
                <button onClick={onConnectToWalletClick} className="text-white text-sm bg-primary py-2 rounded-lg px-4">
                  Connect Wallet
                </button>
              )}
            </div>
            <div className="shrink-0">
              {isConnected && (
                <>
                  {!isUTUConnected() && (
                    <button className="text-white text-sm bg-primary py-2 rounded-lg px-4" onClick={onConnectToUtuClick}>
                      Connect UTU
                    </button>
                  )}
                </>
              )}
            </div>
            <NavbarProfile onConnectToWalletClick={onConnectToWalletClick} />
          </div>
          <div className="lg:hidden block">
            <NavbarProfile onConnectToWalletClick={onConnectToWalletClick} />
          </div>

          <div className="lg:hidden items-center gap-x-2 flex">
            {!isConnected && (
              <button onClick={onConnectToWalletClick} className="text-white w-full flex-1 text-sm bg-primary py-2 rounded-lg px-4">
                Connect <span className="hidden lg:block">Wallet</span>
              </button>
            )}

            <button
              onClick={() => {
                setOpenMenu(true);
              }}
            >
              <MenuIcon />
            </button>
          </div>
        </Container>
      </div>
    </>
  );
}

export default Navbar;
