import { useQuery } from "@apollo/client";
import { GetAllJobsQuery } from "../../../__generated__/graphql";
import { GET_JOB_CHOOSEN_FREELANCER, GET_SUBMISSION_PAYMENT } from "../../../lib/apollo/queries";
import ApplicantProfile from "./ApplicantProfile";
import { Link } from "react-router-dom";
import { useAccount } from "wagmi";
import { freelanceMarketplace } from "../../../lib/web3/trustiffy/trustiffyFreelanceMarketplace";
import { useState } from "react";
import SubmissionCard from "./SubmissionCard";

const ChosenFreelancer = ({
  jobDetails,
  submission,
  canPayFreelancer,
  onSendMessage,
}: {
  jobDetails: GetAllJobsQuery["actions"][0];
  submission?: string;
  canPayFreelancer: boolean;
  onSendMessage: () => void;
}) => {
  const { data, loading } = useQuery(GET_JOB_CHOOSEN_FREELANCER, { variables: { jobId: jobDetails.Trustiffy_id } });
  const { address } = useAccount();
  const [processingPayment, setProcessingPayment] = useState(false);
  const { data: payment, loading: paymentLoading } = useQuery(GET_SUBMISSION_PAYMENT, { variables: { jobId: jobDetails.Trustiffy_id } });
  const paymentMade = payment?.paymentMades[0] && !paymentLoading;

  const payFreelancer = async () => {
    setProcessingPayment(true);
    try {
      await freelanceMarketplace.payFreelancer(jobDetails.Trustiffy_id, { from: address?.toLowerCase() as string });
    } catch (err) {
      console.error(err);
    } finally {
      setProcessingPayment(false);
    }
  };

  if (loading) return <></>;
  return (
    <div className="flex flex-col gap-4">
      <div className="bg-primary px-7 py-10 rounded-lg">
        <button className="flex w-full items-center justify-between">
          <span className="text-lg lg:text-2xl font-black font-freigeist text-white">Choosen Freelancer</span>
        </button>
        <div className="text-white py-5">
          {data?.freelancerChosens.map((chosenFreelancer, index) => (
            <div key={`chosen-freelancer-${index}`} className="flex justify-between gap-2 sm:flex-row w-full flex-col items-start sm:items-center">
              <ApplicantProfile applicantAddress={chosenFreelancer.freelancer} />
              {jobDetails.employer.toLocaleLowerCase() === address?.toLocaleLowerCase() && (
                <div className="flex flex-col sm:flex-row w-full justify-end gap-2">
                  <Link
                    onClick={onSendMessage}
                    to={`/${chosenFreelancer.freelancer}/profile/chat`}
                    className="border w-full sm:w-fit text-center text-xs border-white py-3 text-white px-4 rounded-md"
                  >
                    Send message
                  </Link>
                  {/* Pay a freelancer if a submission exist  */}
                  {submission && canPayFreelancer && !paymentMade && (
                    <button onClick={payFreelancer} className="bg-white w-full sm:w-fit px-4 text-xs py-3 text-primary rounded-md">
                      {processingPayment ? "Processing..." : "Approve the work"}
                    </button>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      {submission && canPayFreelancer && <SubmissionCard freelancerAddress={data?.freelancerChosens[0].freelancer} submission={submission} />}
    </div>
  );
};

export default ChosenFreelancer;
