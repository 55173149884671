export const getRelativeTimeLabel = (dateString?: string | number | Date, options?: { isTimeline: boolean }) => {
  if (!dateString) return "";
  const date = new Date(dateString);
  const now = new Date();

  const diff = now.getTime() - date.getTime();
  const diffSeconds = Math.floor(diff / 1000);
  const diffMinutes = Math.floor(diff / 1000 / 60);
  const diffHours = Math.floor(diff / 1000 / 60 / 60);
  const diffDays = Math.floor(diff / 1000 / 60 / 60 / 24);
  const diffWeeks = Math.floor(diff / 1000 / 60 / 60 / 24 / 7);

  if (diffSeconds < 60) {
    return "now";
  } else if (diffMinutes < 60) {
    return `${diffMinutes} minute${diffMinutes > 1 ? "s" : ""} ${!options?.isTimeline ? "ago" : ""}`;
  } else if (diffHours < 24) {
    return `${diffHours} hour${diffHours > 1 ? "s" : ""} ${!options?.isTimeline ? "ago" : ""}`;
  } else if (diffDays < 7) {
    return `${diffDays} day${diffDays > 1 ? "s" : ""} ${!options?.isTimeline ? "ago" : ""}`;
  } else {
    return `${diffWeeks} week${diffWeeks > 1 ? "s" : ""} ${!options?.isTimeline ? "ago" : ""}`;
  }
};
