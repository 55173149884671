import SideModal from "../ui/SideModal";
import { UTU_SOCIAL_CONNECTOR_APP_URL } from "../../contants/env";
import { useEffect } from "react";
import useClickOutside from "../../hook/useClickOutSide";

const UtuSocialConnector = ({ onCloseUTUConnectorModal, isModalOpened }: { onCloseUTUConnectorModal: () => void; isModalOpened: boolean }) => {
  // TODO: As this code is repeating multiple time we can think of creating a custom hook for it.
  useEffect(() => {
    if (isModalOpened) {
      document.body.className = "overflow-hidden";
    }
    return () => {
      document.body.className = "overflow-auto";
    };
  }, [isModalOpened]);

  const ref = useClickOutside(onCloseUTUConnectorModal);

  return (
    <SideModal ref={ref} title="UTU social connector" onCloseModal={onCloseUTUConnectorModal} className="bg-white w-full max-w-full lg:w-[50%] lg:max-w-[50%] z-[9999]">
      <iframe src={UTU_SOCIAL_CONNECTOR_APP_URL} className="w-full h-full" title="utu social connector" />
    </SideModal>
  );
};

export default UtuSocialConnector;
