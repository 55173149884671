import { JSXElementConstructor, Key, ReactElement, ReactNode, ReactPortal } from 'react';

const Skills = ({ skills }: { skills: any[] }) => {
  if (skills.length === 0) return null;
  return (
    <div className="space-y-5">
      <p className="text-lg font-bold">Skills</p>
      <div className="flex flex-wrap items-center gap-2">
        {skills.map(
          (
            item: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined,
            index: Key | null | undefined,
          ) => (
            <p className="rounded-md px-4 text-primary py-1 bg-primary-white-shade" key={index}>
              {item}
            </p>
          ),
        )}
      </div>
    </div>
  );
};

export default Skills;
