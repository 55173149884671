import truncateString from "../../utils/trancate";
import { getRelativeTimeLabel } from "../../utils/relativeTime";
import { CachedConversation, ContentTypeMetadata } from "@xmtp/react-sdk";
import clsx from "clsx";
import { useXmtp } from "../../contexts/XmtpProvider";
import { useQuery } from "@apollo/client";
import { GET_USER_PROFILE } from "../../lib/apollo/queries";

const ConversationCard = ({ conversation, onClick }: { conversation: CachedConversation<ContentTypeMetadata>; onClick: () => void }) => {
  const xmtp = useXmtp();
  const { data } = useQuery(GET_USER_PROFILE, { variables: { userAddress: conversation.peerAddress } });
  const currentProfile = data?.userProfileActions[0];

  return (
    <div
      onClick={() => {
        xmtp?.setCurrentConversation(conversation);
        xmtp?.setCurrentProfile({ name: currentProfile?.name ?? "", pictureUri: currentProfile?._profileURL ?? "" });
        onClick();
      }}
      className={clsx(conversation.id === 1 ? "border-primary bg-primary-white-shade border-l-2" : "border-t border-primary-shade", "  p-4 cursor-pointer")}
    >
      <div className="flex flex-col justify-between">
        <p className="text-sm line-clamp-1 ">{currentProfile?.name ?? truncateString(conversation.peerAddress)}</p>
        <div className="rounded-full flex items-center justify-center w-fit">
          <p className="text-xs text-green-600">Last sync {getRelativeTimeLabel(conversation.lastSyncedAt)}</p>
        </div>
      </div>
    </div>
  );
};

export default ConversationCard;
