import Container from "../../ui/Container";
import { FaCheckCircle } from "react-icons/fa";

const popularServices = [
  {
    title: "Programming & Tech",
  },
  {
    title: "Digital Marketting",
  },
  {
    title: "Graphics & Design",
  },
  {
    title: "Video & Animation",
  },
  {
    title: "Ui/Ux Design",
  },
];

const FindTalents = () => {
  return (
    <Container>
      <p className="text-lg md:text-4xl mb-14 font-freigeist font-bold">Popular services.</p>
      <div className="grid xl:grid-cols-5 lg:grid-cols-4 grid-cols-2 items-center gap-6">
        {popularServices.map((item, index) => (
          <div
            key={index}
            className={`px-6 w-full ${index === 0 ? "hidden lg:flex" : null} ${
              index === 4 ? "lg:hidden xl:flex" : ""
            } flex items-center rounded-2xl justify-between py-6 md:py-10 bg-[#F7F9FF]`}
          >
            <p className="text-primary truncate  text-sm">{item.title}</p>
            <div className="hidden md:block">
              <FaCheckCircle className="text-primary" />
            </div>
          </div>
        ))}
      </div>
    </Container>
  );
};

export default FindTalents;
