import React, { createContext, useEffect, useContext, useCallback, useState, SetStateAction, Dispatch, useRef } from "react";
import {
  useClient,
  CachedConversation,
  ContentTypeMetadata,
  useConversations,
  useCanMessage,
  useStartConversation,
  Conversation,
  useStreamConversations,
  useStreamAllMessages,
  Client,
  DecodedMessage,
} from "@xmtp/react-sdk";

import { useAccount } from "wagmi";
import Web3Service from "../lib/web3/trustiffy";
import toast from "react-hot-toast";
import { loadKeys, storeKeys } from "../lib/web3/xmpt/storeKeys";

type ConversationProfile = {
  name: string;
  pictureUri: string;
};

type ProviderState = {
  conversations: CachedConversation<ContentTypeMetadata>[];
  sendMessage: (peerAddress: string, message: string) => Promise<void>;
  listeningToConversatonError: Error | null;
  streamedConversations: Conversation[];
  client: Client<any> | undefined;
  currentConversation: CachedConversation | undefined;
  setCurrentConversation: Dispatch<SetStateAction<CachedConversation | undefined>>;
  currentProfile: ConversationProfile;
  setCurrentProfile: Dispatch<SetStateAction<ConversationProfile>>;
};

const XmtpContext = createContext<ProviderState | null>(null);
export const useXmtp = () => useContext(XmtpContext);

export const XmtpContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { address: walletAddress } = useAccount();
  const { initialize, client } = useClient();
  const { conversations } = useConversations();
  const { startConversation } = useStartConversation();
  const { canMessage } = useCanMessage();
  const [currentConversation, setCurrentConversation] = useState<CachedConversation>();
  const [currentProfile, setCurrentProfile] = useState<ConversationProfile>({
    name: "",
    pictureUri: "",
  });
  const xmtpInitialized = useRef(false);
  const [streamedConversations, setStreamedConversations] = useState<Conversation[]>([]);

  const sendMessage = useCallback(
    async (peerAddress: string, message: string) => {
      if (!peerAddress || !message.trim()) return;
      try {
        if (await canMessage(walletAddress as string)) {
          await startConversation(peerAddress, message);
        }
      } catch (error) {
        console.error(error);
        toast.error("Cannot send message for now");
      }
    },
    [canMessage, startConversation, walletAddress],
  );

  const initXmtpWithKeys = useCallback(async () => {
    const options = {
      persistConversations: false,
      env: process.env.NODE_ENV !== "production" ? "production" : ("dev" as "production" | "dev" | "local" | undefined),
    };

    const signer = new Web3Service().signer;
    let keys = loadKeys(walletAddress as string);
    if (!keys) {
      keys = await Client.getKeys(signer, {
        ...options,
        skipContactPublishing: true,
        persistConversations: false,
      });

      storeKeys(walletAddress as string, keys);
    }
    await initialize({ keys, options, signer });
  }, [initialize, walletAddress]);

  useEffect(() => {
    if (!xmtpInitialized.current) {
      void initXmtpWithKeys();
    }

    return () => {
      xmtpInitialized.current = true;
    };
  }, [initXmtpWithKeys]);

  // Listening to new conversations
  const onConversation = useCallback((conversation: Conversation) => {
    setStreamedConversations((prev) => [...prev, conversation]);
  }, []);

  const { error: listeningToConversatonError } = useStreamConversations({ onConversation });

  // sent a toast for all new messages
  const onMessage = useCallback(
    (message: DecodedMessage) => {
      if (message.senderAddress !== walletAddress) {
        toast.success("New message", { position: "bottom-right" });
      }
    },
    [walletAddress],
  );

  useStreamAllMessages(onMessage);

  return (
    <XmtpContext.Provider
      value={{
        conversations,
        client,
        sendMessage,
        currentProfile,
        setCurrentProfile,
        streamedConversations,
        listeningToConversatonError,
        currentConversation,
        setCurrentConversation,
      }}
    >
      {children}
    </XmtpContext.Provider>
  );
};
