import { useState } from "react";
import EditProfileModal from "../profile/partial/EditProfileModal";
import { useQuery } from "@apollo/client";
import { GET_USER_PROFILE } from "../../lib/apollo/queries";
import { useAccount } from "wagmi";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { cx } from "class-variance-authority";

const VerifyProfileDisclaimer = ({ className }: { className?: string }) => {
  const { isConnected, address } = useAccount();
  const { data, loading: isLoading } = useQuery(GET_USER_PROFILE, { variables: { userAddress: address } });
  const [isEditProfileModal, setIsEditProfileModal] = useState(false);
  const { open } = useWeb3Modal();
  const toggleEditProfileModal = () => setIsEditProfileModal((prev) => !prev);
  const profile = data?.userProfileActions[0];

  const onConnectToWalletClick = async () => {
    await open();
  };

  if (!isConnected)
    return (
      <p className={cx("border border-primary-shade p-2 text-primary w-fit text-sm", className)}>
        You are not set yet,{" "}
        <span className="underline" role="button" onClick={onConnectToWalletClick}>
          connect your wallet
        </span>
      </p>
    );

  if (isLoading || profile?.verified) return <></>;
  return (
    <>
      {isEditProfileModal && <EditProfileModal isOpen={isEditProfileModal} onClose={toggleEditProfileModal} />}
      <p className={cx("border border-primary-shade p-2 text-primary w-fit text-sm", className)}>
        You are not fully set yet,{" "}
        <span role="button" onClick={toggleEditProfileModal} className="underline">
          verify your profile
        </span>
      </p>
    </>
  );
};

export default VerifyProfileDisclaimer;
