import WalletAddress from "../../ui/WalletAddress";
import { Profile } from "../../../types/profileData";
import { RiMedalFill } from "react-icons/ri";
import TrustiffyReputations from "../../ui/TrustifyReputation";

const ProfileImageAndName = ({ profile, isLoading }: { profile: Profile; isLoading: boolean }) => {
  return (
    <div className="flex space-y-5 flex-col w-full items-center justify-center">
      {/* @ts-ignore */}
      <img alt="" className={`object-cover w-28 h-28 rounded-full ${isLoading && "bg-primary-shade animate-pulse"}`} loading="lazy" src={profile?._profileURL} />
      <p className="font-bold text-xl flex flex-col items-center gap-2 justify-center">{profile?.name && <span>{profile.name}</span>}</p>

      <div className="flex justify-between w-full items-center gap-x-3">
        <span className="text-xs px-4 py-2 border border-green-300 bg-green-500/20 text-green-500 rounded-md">
          <WalletAddress address={profile?.userAddress as string} />
        </span>
        <div className="flex text-xs uppercase bg-orange-500 rounded-md text-white items-center px-2 py-2 gap-x-3">
          <span>
            <TrustiffyReputations className="w-4 h-4" />
          </span>
          <span>Reputations</span>
          <span>(10)</span>
        </div>
      </div>
    </div>
  );
};

export default ProfileImageAndName;
