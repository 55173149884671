import { useQuery } from "@apollo/client";
import React from "react";
import { GET_USER_PROFILE } from "../../../lib/apollo/queries";
import truncateString from "../../../utils/trancate";

const ApplicantProfile = ({ applicantAddress }: { applicantAddress: string }) => {
  const { data, loading } = useQuery(GET_USER_PROFILE, { variables: { userAddress: applicantAddress } });
  if (loading || !data) {
    return (
      <div>
        <p className="text-sm border border-green-500 text-green-700 w-full lg:w-fit py-1 px-5 text-center bg-green-500/10 rounded-md font-semibold">
          {truncateString(applicantAddress)}
        </p>
      </div>
    );
  }

  return (
    <div className="flex gap-2 items-center w-full">
      <div className="w-[30px] h-[30px]">
        <img className="w-full h-full object-cover rounded-full" alt={data.userProfileActions[0].name} src={data.userProfileActions[0]._profileURL} />
      </div>
      <p className="font-bold">{data.userProfileActions[0].name}</p>
    </div>
  );
};

export default ApplicantProfile;
