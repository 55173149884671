import React, { Dispatch, SetStateAction } from 'react';
import { EditorContent, useEditor } from '@tiptap/react';
import { StarterKit } from '@tiptap/starter-kit';
import TipTapMenuBar from './TipTapMenubar';

type Props = {
  note: {
    editorState?: string;
    name: string;
  };
  setNote: Dispatch<SetStateAction<string | null>>;
};

const TipTapEditor = ({ note, setNote }: Props) => {
  const [editorState, setEditorState] = React.useState(note.editorState || '<p></p>');

  const editor = useEditor({
    autofocus: true,
    extensions: [StarterKit],
    content: editorState,
    onUpdate: ({ editor }) => {
      setEditorState(editor.getHTML());
    },
  });

  React.useEffect(() => {
    setNote(editorState);
  }, [editorState, setNote]);
  return (
    <>
      <div className="flex">{editor && <TipTapMenuBar editor={editor} />}</div>
      <div className="prose prose-sm w-full">
        <EditorContent className="w-full min-h-[100px]" editor={editor} />
      </div>
    </>
  );
};

export default TipTapEditor;
