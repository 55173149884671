import { useAccount } from "wagmi";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_USER_PROFILE } from "../../lib/apollo/queries";
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from "./dropdown-menu";
import truncateString from "../../utils/trancate";
import clsx from "clsx";
import EditProfileModal from "../profile/partial/EditProfileModal";
import { useState } from "react";

const NavbarProfile = ({ onConnectToWalletClick }: { onConnectToWalletClick: () => void }) => {
  const navigate = useNavigate();
  const { isConnected, address } = useAccount();
  const { data, loading: isLoading } = useQuery(GET_USER_PROFILE, { variables: { userAddress: address } });
  const [isEditProfileModal, setIsEditProfileModal] = useState(false);
  const toggleEditProfileModal = () => setIsEditProfileModal((prev) => !prev);
  const profile = data?.userProfileActions[0];
  if (isLoading) return <div className="w-8 h-8 rounded-full bg-primary-shade animate-pulse" />;

  return (
    <>
      {isEditProfileModal && <EditProfileModal isOpen={isEditProfileModal} onClose={toggleEditProfileModal} />}

      <div>
        {isConnected && address && (
          <DropdownMenu>
            <DropdownMenuTrigger className="hover:cursor-pointer" asChild>
              {!profile?.verified ? (
                <>
                  <img
                    alt="img"
                    onClick={() => setIsEditProfileModal(true)}
                    className="object-contain cursor-pointer rounded-full"
                    width={30}
                    height={30}
                    loading="lazy"
                    src="/mocks/placeholder.png"
                  />
                  {/* <EditProfileButton as="verificationButton" /> */}
                </>
              ) : (
                <img alt={profile.name} className="object-cover shrink-0 cursor-pointer rounded-full w-8 h-8" loading="lazy" src={profile._profileURL} />
              )}
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56 lg:mr-10 mr-4 mt-4 lg:mt-5">
              <div className="px-4 py-4">
                <div className="flex flex-col gap-y-3 items-center">
                  <div className="w-15 h-15 rounded-full bg-blue-400">
                    <div>
                      {!profile?.verified ? (
                        <>
                          <img alt="img" className="object-contain cursor-pointer rounded-full" width={30} height={30} loading="lazy" src="/mocks/placeholder.png" />
                        </>
                      ) : (
                        <img alt={profile.name} className="object-cover shrink-0 cursor-pointer rounded-full w-8 h-8" loading="lazy" src={profile._profileURL} />
                      )}
                    </div>
                  </div>
                  <p className="text-sm font-freigeist max-w-[70%] truncate w-full text-center font-bold">
                    <span className="text-primary text-sm ] lg:text-base truncate">Hi,</span> {profile?.name.split(" ").at(0)}
                  </p>
                  {profile?.verified ? (
                    <button onClick={() => navigate(`${address}/profile/jobs`)} className="w-full rounded-md text-white bg-primary lg:text-sm font-medium py-3  text-xs ">
                      View Profile
                    </button>
                  ) : (
                    <button onClick={() => setIsEditProfileModal(true)} className="w-full rounded-md text-white bg-primary lg:text-sm font-medium py-3  text-xs ">
                      Verifier Profile
                    </button>
                  )}

                  <button
                    className={clsx(!isConnected ? "text-white bg-primary" : " border-red-400 text-red-600 border", "text-xs lg:text-sm  w-full rounded-md  py-3")}
                    onClick={onConnectToWalletClick}
                  >
                    {isConnected ? "Disconnect" : "Connect"}
                  </button>
                  <p className="text-neutral-600 text-[10px]">
                    Account connected: <span className="text-primary">{truncateString(address, 4, 4)}</span>
                  </p>
                </div>
              </div>
            </DropdownMenuContent>
          </DropdownMenu>
        )}
      </div>
    </>
  );
};

export default NavbarProfile;
