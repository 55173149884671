export function formatPictureUrl(picture: any) {
  if (!picture) return '/mocks/placeholder.png';
  if (picture.__typename === 'MediaSet') {
    if (picture.original.url.startsWith('ipfs://')) {
      const result = picture.original.url.substring(7, picture.original.url.length);
      return `http://lens.infura-ipfs.io/ipfs/${result}`;
    } else {
      return picture.original.url;
    }
  }
}
