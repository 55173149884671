import ArrowIcon from "../../ui/icons/arrowIcon";

const SubmissionCard = ({ submission, freelancerAddress }: { submission: string; freelancerAddress: string }) => {
  if (!submission || !freelancerAddress) return null;
  return (
    <a
      target="_blank"
      rel="noreferrer"
      className="flex items-center justify-center gap-2 border text-primary border-primary rounded-[5px] py-4 px-7 text-xs font-normal w-full sm:w-fit my-5"
      href={submission}
    >
      View submitted work
      <span>
        <ArrowIcon />
      </span>
    </a>
  );
};

export default SubmissionCard;
