import { ChangeEvent, useEffect, useState } from "react";
import { useXmtp } from "../../contexts/XmtpProvider";
import { isValidAddress, useCanMessage } from "@xmtp/react-sdk";
import { MoreVertical, Send, ArrowLeft } from "lucide-react";
import truncateString from "../../utils/trancate";
import MessagesContainer from "../../components/chat/MessagesContainer";
import ConversationPannel from "../../components/chat/ConversationPannel";
import toast from "react-hot-toast";

const Chat = () => {
  const xmtp = useXmtp();
  const [message, setmessage] = useState({
    receiver: "",
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isOnNetwork, setIsOnNetwork] = useState(false);
  const { canMessage } = useCanMessage();

  const onMessageInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setmessage((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const onSendMessage = async () => {
    const { receiver, message: messageToSend } = message;
    const exactReceiver = xmtp?.currentConversation?.peerAddress ?? receiver;
    if (!exactReceiver || !messageToSend) {
      toast.error("Please select a conversation first", { position: "top-right" });
      return;
    }

    try {
      setIsLoading(true);
      await xmtp?.sendMessage(exactReceiver, messageToSend);
      setmessage({
        receiver: "",
        message: "",
      });
    } catch (err: any) {
      toast.error(err.reason, { position: "top-right" });
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const checkAddress = async () => {
      if (isValidAddress(message.receiver)) {
        setIsLoading(true);
        setIsOnNetwork((await xmtp?.client?.canMessage(message.receiver)) as boolean);
        setIsLoading(false);
      } else {
        setIsOnNetwork(false);
      }
    };
    if (!message.receiver) return;
    void checkAddress();
  }, [canMessage, message.receiver]);

  return (
    <div>
      <ChatComponents messageValue={message.message} onMessageInputChange={onMessageInputChange} onSendMessage={onSendMessage} />
    </div>
  );
};

export default Chat;

const ChatComponents = ({
  onMessageInputChange,
  onSendMessage,
  messageValue,
}: {
  onMessageInputChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onSendMessage: () => void;
  messageValue: string;
}) => {
  const xmtp = useXmtp();
  const [chatPanelOpened, setChatPanelOpened] = useState(false);

  const toggleChatPanel = () => {
    setChatPanelOpened((prev) => !prev);
  };

  return (
    <div className="flex lg:gap-x-8 items-stretch">
      <div className={`rounded-xl md:max-h-[30rem] overflow-y-auto lg:block h-fit bg-white  flex-1 basis-2/6 overflow-hidden ${!chatPanelOpened ? "block" : "lg:block hidden"}`}>
        <div className="flex py-4 text-primary px-4 items-center gap-x-2">
          <div>
            <p className="text-sm">Conversations</p>
          </div>
        </div>
        <div>
          <ConversationPannel onConversationClicked={toggleChatPanel} />
        </div>
      </div>

      <div className={`rounded-xl flex flex-col w-full min-h-[40rem] max-h-[40rem] lg:basis-4/6 overflow-hidden ${chatPanelOpened ? "flex" : "hidden lg:flex"}`}>
        <div className="flex py-4 px-6 bg-white items-center justify-between">
          <div className="flex items-center gap-x-3">
            <ArrowLeft className="text-primary text-sm block lg:hidden cursor-pointer" onClick={toggleChatPanel} />
            <div className="relative flex items-center gap-3">
              <img alt="img" className="object-contain rounded-full" width={26} height={26} loading="lazy" src={xmtp?.currentProfile.pictureUri || "/mocks/placeholder.png"} />
              <div className="bg-green-500 rounded-full absolute -bottom-1 w-3 -right-1 h-3 border-[2px] border-white" />
            </div>
            <div>
              <p>{xmtp?.currentProfile.name || truncateString(xmtp?.currentConversation?.peerAddress)}</p>
            </div>
          </div>
          <div>
            <MoreVertical />
          </div>
        </div>
        <div className="bg-primary-white-shade grow md:max-h-[30rem] overflow-y-auto">
          {xmtp?.currentConversation && <MessagesContainer conversation={xmtp.currentConversation} />}
        </div>
        <div className="p-4 rounded-b-xl bg-white">
          <div className="flex items-center gap-x-1">
            <div className="flex-1 bg-primary-white-shade">
              <input
                onKeyDown={(e) => {
                  if (e.key === "Enter") onSendMessage();
                }}
                type="text"
                placeholder="Type a message ..."
                onChange={onMessageInputChange}
                name="message"
                value={messageValue}
                id="chat"
                className="w-full py-2 px-4 bg-transparent placeholder:text-primary/60 outline-none"
              />
            </div>
            <button onClick={onSendMessage} className="p-2 bg-primary rounded-lg text-white">
              <Send size={20} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
