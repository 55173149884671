import { ethers } from 'ethers';
import { FreelanceMarketplace } from '../../../contants/contractAddresses';
import freelanceMarketplaceABI from '../../../contants/FreelanceMarketplace.json';
import { polygonMumbai, polygon } from 'wagmi/chains';
import { TRUSTIFFY_CONNECTED_ACCOUNT } from '../../../utils/localstorage';

export const SUPPORTED_CHAINS = {
  testnet: {
    polygon: {
      name: polygonMumbai.name,
      network: polygonMumbai.network,
      blockExplorer: polygonMumbai.blockExplorers.default.url,
      nodeRPC: polygonMumbai.rpcUrls.default.http[0],
      chainId: polygonMumbai.id,
      mintContractAddress: polygonMumbai.contracts.multicall3.address,
      currency: polygonMumbai.nativeCurrency.symbol,
    },
  },
  mainnet: {
    polygon: {
      name: polygon.name,
      network: polygon.network,
      blockExplorer: polygon.blockExplorers.default.url,
      nodeRPC: polygon.rpcUrls.default.http[0],
      chainId: polygon.id,
      mintContractAddress: polygon.contracts.multicall3.address,
      currency: polygon.nativeCurrency.symbol,
    },
  },
};

const CHAINS_ENV = process.env.NODE_ENV === 'production' ? SUPPORTED_CHAINS.mainnet : SUPPORTED_CHAINS.testnet;
export default class Web3Service {
  public provider: ethers.providers.JsonRpcProvider;
  public freelanceContract: ethers.Contract;
  public signer: ethers.providers.JsonRpcSigner;

  constructor() {
    //@ts-ignore
    this.provider = window.ethereum ? new ethers.providers.Web3Provider(window.ethereum) : new ethers.providers.JsonRpcProvider(CHAINS_ENV.polygon.nodeRPC);
    //@ts-ignore
    this.signer = this.provider.getSigner(localStorage.getItem(TRUSTIFFY_CONNECTED_ACCOUNT) ?? undefined);
    this.freelanceContract = new ethers.Contract(FreelanceMarketplace, freelanceMarketplaceABI.abi, this.signer);
  }

  etherParser(value: number, unit?: string) {
    return ethers.utils.parseUnits(value.toString(), unit);
  }

  weiParser(value: number) {
    return ethers.utils.parseEther(value.toString()).toString();
  }

  async transactionResult(tx: any) {
    return await tx.wait();
  }
}
