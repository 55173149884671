const NotFound = ({ title }: { title: string }) => {
  return (
    <div className="flex flex-col gap-10 justify-center items-center">
      <p className="text-primary font-freigeist text-lg font-bold">{title}</p>
      <div className="w-96">
        <img src="/trustiffy-page-not-found.png" alt="not found" />
      </div>
    </div>
  );
};

export default NotFound;
