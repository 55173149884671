import { ComponentProps } from "react";
import { IoMdSearch } from "react-icons/io";

const SearchInput = (props: ComponentProps<"input">) => {
  return (
    <div className="flex w-full h-fit gap-x-1 items-stretch">
      <div className=" w-full border border-primary-shade rounded-md">
        <input
          {...props}
          placeholder="Search for any service"
          className="bg-transparent placeholder:font-light placeholder:text-primary/80 placeholder:text-sm text-primary py-2 px-4 w-full outline-none"
        />
      </div>
      <span className="bg-primary flex items-center justify-center  py-2 px-3 rounded-md">
        <IoMdSearch size={20} className="text-white" />
      </span>
    </div>
  );
};

export default SearchInput;
