import React, { useEffect } from "react";
import { DisplayContainerVariants } from "../../utils/utils";
import { motion } from "framer-motion";
import useClickOutside from "../../hook/useClickOutSide";

const MobileModal = ({ children, onClose, isModalOpen }: { isModalOpen: boolean; children: React.ReactNode; onClose: () => void }) => {
  const ref = useClickOutside(onClose);
  useEffect(() => {
    if (isModalOpen) {
      document.body.className = "overflow-hidden";
    }
    return () => {
      document.body.className = "overflow-auto";
    };
  }, [isModalOpen]);

  return (
    <div className="bg-black backdrop-blur bg-opacity-10 fixed z-[200] flex items-center justify-center inset-0">
      <motion.div
        ref={ref}
        variants={DisplayContainerVariants}
        initial="hidden"
        animate="visible"
        exit="hidden"
        className="absolute right-0 max-w-full flex flex-col pb-2 bg-neutral-100 w-[80%] h-full overflow-y-auto rounded-l-xl"
      >
        {children}
      </motion.div>
    </div>
  );
};

export default MobileModal;
