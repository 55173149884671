import clsx from "clsx";

const HorizontalBorderComponent = ({ className }: { className?: string }) => {
  return (
    <div className={clsx(className, "relative w-full flex items-center")}>
      <div className="h-1 w-1 rotate-45 bg-primary-shade" />
      <div className="h-[0.5px] w-full bg-primary-shade" />
      <div className="absolute right-0 h-1 w-1 rotate-45 bg-primary-shade" />
    </div>
  );
};

export default HorizontalBorderComponent;

export const VerticalBorderComponent = ({ className }: { className?: string }) => {
  return (
    <div className={clsx(className, "relative h-full w-fit flex flex-col items-center")}>
      <div className="h-1 w-1 top-0 rotate-45 bg-primary" />
      <div className="w-[2px] h-full bg-primary" />
      <div className="absolute bottom-0 h-1 w-1 rotate-45 bg-primary" />
    </div>
  );
};
