import { useRef } from "react";
import { Canvas, useFrame, useLoader } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { Mesh } from "three";

function MainMeshComponent() {
  const fileUrl = "/Hero_page.glb";
  const mesh = useRef<Mesh>(null!);
  const gltf = useLoader(GLTFLoader, fileUrl);

  useFrame(() => {
    mesh.current.rotation.y += 0.01;
  });

  return (
    <mesh ref={mesh}>
      <primitive object={gltf.scene} />
    </mesh>
  );
}

export function Trustiffy3D() {
  return (
    <Canvas camera={{ position: [0, 0, 5], fov: 70 }}>
      <OrbitControls enableZoom={false} />

      {/* Increase ambient light intensity */}
      <ambientLight intensity={5} />

      {/* Increase spot light intensity */}
      <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} decay={0} intensity={2} />

      {/* Increase point light intensity */}
      <pointLight position={[-10, -10, -10]} decay={0} intensity={2} />

      <MainMeshComponent />
    </Canvas>
  );
}
