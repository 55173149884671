import Web3Service from '.';
import { ethers } from 'ethers';
import CreateJobResult from '../../../types/createJobResult';

class FreelanceMarketplace extends Web3Service {
  /**
   * @dev Creates a new job listing on the Trustiffy platform.
   * @param title The title or name of the job.
   * @param description A detailed description of the job.
   * @param paymentAmount The payment amount associated with the job listing.
   * @param jobType The type of job (e.g., Full-time, Part-time, One-time).
   * @param duration The expected duration of the job.
   * @param categories An array of categories associated with the job.
   * @param skills An array of skills required for the job.
   */
  async createJob(
    ConnectedAddress: string,
    title: string,
    description: string,
    paymentAmount: number,
    jobType: string,
    duration: number,
    categories: string[],
    skills: string[],
  ): Promise<CreateJobResult> {
    const cost = ethers.utils.parseUnits(paymentAmount.toString());
    const tx = await this.freelanceContract.createJob(title, description, cost, jobType, duration, categories, skills, {
      from: ConnectedAddress,
      value: cost,
    });

    const result = await tx.wait();
    return result;
  }

  /**
   * @dev Updates the details of an existing job listing.
   * @param _jobId The unique identifier of the job listing to be updated.
   * @param _title The updated title or name of the job.
   * @param _description The updated description of the job.
   * @param _paymentAmount The updated payment amount associated with the job listing.
   * @param _jobType The updated type of job.
   * @param _location The updated location of the job.
   * @param _priceType The updated pricing type.
   * @param _duration The updated expected duration of the job.
   * @param _categories The updated array of categories associated with the job.
   * @param _skills The updated array of skills required for the job.
   */

  async updateJob(
    ConnectedAddress: string,
    jobId: number,
    title: string,
    description: string,
    paymentAmount: number,
    jobType: string,
    location: string,
    priceType: string,
    duration: number,
    categories: string[],
    skills: string[],
  ): Promise<CreateJobResult> {
    const cost = ethers.utils.parseUnits(paymentAmount.toString());
    const tx = await this.freelanceContract.updateJob(jobId, title, description, cost, jobType, location, priceType, duration, categories, skills, { from: ConnectedAddress });

    const result = await tx.wait();
    return result;
  }

  /**
   * @dev Allows a freelancer to apply for a job listing.
   * @param _jobId The unique identifier of the job listing.
   * @param _cvMetadataURI The URI or metadata of the freelancer's CV or application details.
   */
  async applyForJob(jobId: number) {
    const tx = await this.freelanceContract.applyForJob(jobId);
    const result = await tx.wait();
    return result;
  }

  /**
   * @dev Retrieves the list of job applicants for a specific job listing.
   * @param _jobId The unique identifier of the job listing.
   * @return An array of Application structures representing the job applicants.
   */
  async getJobApplications(jobId: number) {
    const allApplicants = await this.freelanceContract.getJobApplications(jobId);
    return allApplicants;
  }

  /**
   * @dev Allows an employer to choose a freelancer from applicants for a job listing and deduct the lisying fee.
   * @param _jobId The unique identifier of the job listing.
   * @param _chosenFreelancer The address of the chosen freelancer.
   */
  async chooseFreelance(jobId: number, chooseFreelance: string) {
    const tx = await this.freelanceContract.chooseFreelancer(jobId, chooseFreelance);
    const result = await tx.wait();
    return result;
  }

  /**
   * @dev Allows a freelancer to submit work for a job listing.
   * @param _jobId The unique identifier of the job listing.
   * @param _submissionLink The link or details of the work submission.
   */
  async submitWork(jobId: number, uri: string) {
    const tx = await this.freelanceContract.submitWork(jobId, uri);
    const result = await tx.wait();
    return result;
  }

  /**
   * @dev Allows an employer to make a payment to the chosen freelancer for completed work.
   * @param _jobId The unique identifier of the job listing.
   */
  async payFreelancer(jobId: number, { from }: { from: string }) {
    const tx = await this.freelanceContract.payFreelancer(jobId, { from });
    await tx.wait();
  }

  /**
   * @dev Retrieves all job listings on the Trustiffy platform.
   * @return An array of Job structures representing all the jobs listed.
   */
  async getAllJobs() {
    let counter = Number((await this.freelanceContract.jobCount()).toString()) - 1;
    const jobs = [];
    while (counter > -1) {
      const _job = await this.freelanceContract.jobs(counter);
      const formatedJob = {
        id: _job.id.toString(),
        employer: _job.employer,
        title: _job.title,
        description: _job.description,
        freelancer: _job.freelancer,
        submission: _job.submission,
        applicantsIds: _job.applicants ?? [],
        listedOn: _job.timestamp.listedOn.toString(),
        duration: _job.timestamp.duration.toString(),
        amount: _job?.jobPayment.amount.toString(),
        jobType: _job?.jobPayment.jobType,
        accepted: _job.accepted,
        skills: _job.skills ?? [],
        status: _job.status,
      };
      jobs.push(formatedJob);
      counter--;
    }
    return jobs as typeof jobs;
  }

  /**
   * @dev Allows an employer to unlist a job listing, refunding the payment.
   * @param _jobId The unique identifier of the job listing.
   */
  async unlistJob(jobId: number, ConnectedAddress: string) {
    const tx = await this.freelanceContract.unlistJob(jobId, { from: ConnectedAddress });
    await tx.wait();
  }

  /**
   * @dev Allows an employer to relist a job listing that was previously unlisted.
   * @param _jobId The unique identifier of the job listing.
   */
  async relistJob(jobId: number, ConnectedAddress: string) {
    const tx = await this.freelanceContract.relistJob(jobId, { from: ConnectedAddress });
    await tx.wait();
  }

  /**
   * @dev Updates the listing fee for creating new job listings.
   * @param _fee The new listing fee to be set.
   */
  async updateFee(fee: number) {
    const charge = ethers.utils.parseUnits(fee.toString());
    const tx = await this.freelanceContract.updateFee(charge);
    await tx.wait();
  }

  /**
   * @dev Function for the owner to withdraw accumulated listing fees.
   * Transfers the accumulated listing fees to the owner's address.
   */
  // Function for the owner to withdraw accumulated listing fees
  async withdrawListingFees(ConnectedAddress: string) {
    const tx = await this.freelanceContract.withdrawListingFees({ from: ConnectedAddress });
    await tx.wait();
  }

  /**
   * @dev Retrieves a user's profile, including both listed and completed works.
   * @param _user The address of the user for whom the profile is requested.
   */
  async getUserProfile(userAddress: string) {
    const user = await this.freelanceContract.userProfiles(userAddress, 0);
    return user;
  }

  // async getAllVerifiedUsers() {
  //   const users = await this.freelanceContract.userProfiles.call();
  //   return users;
  // }

  async getUserSubmittedWork(userAddress: string) {
    const submittedWork = await this.freelanceContract.submittedWorks(userAddress, 0);
    return submittedWork;
  }
}

export const freelanceMarketplace = new FreelanceMarketplace();
