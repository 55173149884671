import { ComponentProps } from "react";

const arrowIcon = (props: ComponentProps<"svg">) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
      <g clipPath="url(#clip0_1233_2135)">
        <path d="M5.625 3.125V4.375H9.74375L2.5 11.6187L3.38125 12.5L10.625 5.25625V9.375H11.875V3.125H5.625Z" fill="#254CD4" />
      </g>
      <defs>
        <clipPath id="clip0_1233_2135">
          <rect width="15" height="15" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default arrowIcon;
