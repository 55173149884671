import { useMessages } from "@xmtp/react-sdk";
import type { CachedConversation, DecodedMessage } from "@xmtp/react-sdk";
import { useEffect, useState } from "react";

export const useStreamMessage = (conversation: CachedConversation) => {
  // track streamed messages
  const [streamedMessages, setStreamedMessages] = useState<DecodedMessage[]>([]);

  const { messages: chatHistory } = useMessages(conversation);

  useEffect(() => {
    setStreamedMessages([]);
  }, [conversation]);

  return { streamedMessages, chatHistory };
};
