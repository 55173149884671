import React from "react";
import "./index.css";

import { WagmiConfig } from "wagmi";
import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";
import { polygonMumbai } from "wagmi/chains";
import App from "./App";
import { createRoot } from "react-dom/client";
import { ApolloProvider } from "@apollo/client";
import apolloClient from "./lib/apollo";
import { XmtpContextProvider } from "./contexts/XmtpProvider";
import { XMTPProvider } from "@xmtp/react-sdk";
import { Buffer } from "buffer";
import ProfileProvider from "./contexts/ProfileProvider";
import ConnectListener from "./contexts/OnDisconnectListener";
import { Analytics } from "@vercel/analytics/react";

window.Buffer = window.Buffer ?? Buffer;
const chains = [polygonMumbai];

// Change this projectId to your one - see instructions in README.md
const projectId = "537fe65180c29d72b446d29c535179d4";

const wagmiConfig = defaultWagmiConfig({ chains, projectId });

const root = createRoot(document.getElementById("root")!);

createWeb3Modal({ wagmiConfig, projectId, chains });

root.render(
  <React.StrictMode>
    <ApolloProvider client={apolloClient}>
      <WagmiConfig config={wagmiConfig}>
        <XMTPProvider>
          <XmtpContextProvider>
            <ProfileProvider>
              <ConnectListener>
                <App />
              </ConnectListener>
            </ProfileProvider>
          </XmtpContextProvider>
        </XMTPProvider>
      </WagmiConfig>
    </ApolloProvider>
    <Analytics />
  </React.StrictMode>,
);
