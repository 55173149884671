import truncateString from "../utils/trancate";
import { useNavigate } from "react-router-dom";
import "@ututrust/web-components";
import { useAccount } from "wagmi";
import Container from "../components/ui/Container";
import { useQuery } from "@apollo/client";
import { GET_ALL_TALENTS, GET_USER_PROFILE } from "../lib/apollo/queries";
import { Profile } from "../types/profileData";
import { UTU_API_URL } from "../contants/env";
import { TalentLoadingSkeleton } from "../components/ui/LoadingSkeleton";
import TrustiffyReputations from "../components/ui/TrustifyReputation";
import Filters from "../components/filter";
import useFilter from "../hook/useFilter";

import { isUTUConnected } from "../utils/utu";
import NotFound from "../components/NotFound";
declare global {
  namespace JSX {
    // prevents typescript errors for the tags
    interface IntrinsicElements {
      "x-utt-balance": any;
      "x-utu-app-link": any;
      "x-utu-wallet-disconnect": any;
      "x-utu-root": any;
      "x-utu-recommendation": any;
      "x-utu-feedback-details-popup": any;
      "x-utu-feedback-form-popup": any;
    }
  }
}

export const TalentCard = ({ data }: { data: Profile }) => {
  const navigate = useNavigate();

  const { address } = useAccount();
  // TODO: Will ne need a better optimization
  const { data: updatedUser } = useQuery(GET_USER_PROFILE, { variables: { userAddress: data?.userAddress } });
  const updatedProfile = updatedUser?.userProfileActions[0];

  if (address?.toLowerCase() === data?.userAddress.toLowerCase()) return;
  return (
    <div>
      <div className="p-4 gap-x-5  border border-primary/20 bg-white rounded-xl">
        <div>
          <div className="relative overflow-hidden rounded-xl  w-full">
            <img src="/mocks/coverholder.jpg" width={200} height={200} alt="cover-holder" loading="lazy" className="h-[7rem] w-full object-container" />
          </div>
          <div className="mx-auto  z-[2000]  -mt-10 w-16 max-w-[64px] max-h-[64px] h-16  rounded-full">
            <img alt="" src={updatedProfile?._profileURL} width={100} height={100} className="rounded-full relative bg-primary object-cover w-full h-full" />
          </div>
        </div>

        <div className="space-y-2 lg:space-y-3">
          <p className="text-center line-clamp-1 font-bold py-2">{updatedProfile?.name}</p>
          <div className="">
            <div className="flex gap-x-3 line-clamp-1 ">
              {updatedProfile?._skills.slice(0, 4).map((skill, index) => (
                <p className="px-3 text-xs truncate  max-w-[100px] md:max-w-full text-primary  py-1 w-fit rounded bg-primary/10" key={index}>
                  {skill}
                </p>
              ))}
            </div>
          </div>
          <div className="flex text-sm  items-center justify-between">
            <div className="flex  border border-green-300 bg-green-500/20 text-green-500 rounded-md items-center px-4 py-2 gap-x-3">
              <p>{truncateString(updatedProfile?.userAddress || "0xdBc8997C1273bD8bc5af15f16df26C4FA03c0852", 3, 3)}</p>
            </div>
            <div className="flex bg-orange-500 rounded-md text-white items-center px-4 py-2 gap-x-2">
              <div>
                <TrustiffyReputations className="w-4 h-4" />
              </div>
              <p className="text-sm uppercase">Reputations</p>
              <p className="text-sm">(10)</p>
            </div>
          </div>
          <div>
            <button
              onClick={() => navigate(`/${updatedProfile?.userAddress}/profile/jobs`)}
              className="w-full text-xs md:text-base truncate px-3 rounded-md bg-primary text-white py-3"
            >
              View Profile
            </button>
          </div>
        </div>
      </div>
      {address && isUTUConnected() && (
        <div>
          <x-utu-root
            style={{
              fontFamily: "POPPINS",
            }}
            source-uuid={address?.toLowerCase()}
            target-type="provider"
          >
            <div className="flex gap-1 justify-between text-sm">
              <x-utu-feedback-details-popup
                style={{
                  fontFamily: "POPPINS",
                }}
                api-url={UTU_API_URL}
                target-uuid={updatedProfile?.userAddress.toLowerCase()}
                source-uuid={address?.toLowerCase()}
              />
              <x-utu-feedback-form-popup
                style={{
                  fontFamily: "POPPINS",
                }}
                api-url={UTU_API_URL}
                source-uuid={address?.toLowerCase()}
                target-uuid={updatedProfile?.userAddress.toLowerCase()}
                transaction-id={5}
              />
            </div>
            <x-utu-recommendation
              style={{
                fontFamily: "POPPINS",
              }}
              target-uuid={updatedProfile?.userAddress.toLowerCase()}
            />
          </x-utu-root>
        </div>
      )}
    </div>
  );
};

const TalentPool = () => {
  // const { data, loading } = useQuery(GET_ALL_TALENTS);

  const { data, onSelectFilters, loading } = useFilter(GET_ALL_TALENTS, { isTalent: true });
  const talents = data?.userProfileActions;

  if (loading) return <TalentLoadingSkeleton />;
  return (
    <div>
      <Container>
        <Filters withSort={false} onSelectFilters={onSelectFilters} onSelectSort={() => null} sortName="recent" />
      </Container>
      {!data || data?.userProfileActions.length === 0 ? (
        <div className="my-20">
          <NotFound title="No talents found" />
        </div>
      ) : (
        <Container className="grid  pb-20 mt-5 gap-4 lg:grid-cols-3 grid-cols-1">
          {talents?.map((element, index) => (
            <TalentCard key={index} data={element} />
          ))}
        </Container>
      )}
    </div>
  );
};

export default TalentPool;
