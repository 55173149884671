import { ComponentProps } from "react";

const SendIcon = (props: ComponentProps<"svg">) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
      <g clipPath="url(#clip0_1233_2410)">
        <path d="M11.8696 28.9909L25.9366 10.9993L3.20603 13.213L6.56643 19.3536L20.6773 13.8871L8.49166 22.8598L11.8696 28.9909Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_1233_2410">
          <rect width="24" height="24" fill="white" transform="translate(0 11.5508) rotate(-28.7709)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SendIcon;
